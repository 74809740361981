import React, { useState, useContext, useEffect } from 'react';
import {
  Breadcrumb,
  Button,
  Row,
  Col,
  Select,
  Table,
  Modal,
  Form,
  Input,
  DatePicker,
  notification,
  Tabs,
  Badge,
  InputNumber,
  Radio,
  Collapse,
  Tooltip,
} from 'antd';
import {
  IoAdd, IoPencil, IoTrash, IoOptionsOutline,
} from 'react-icons/io5';
import moment from 'moment';

//  Custom Imports
import './addStock.scss';
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import {
  DELETE, GET, POST,
} from '../../common/crud';
import { dateFormat } from '../../common/common';

const ListInventory = () => {
  const [bmData, setBmData] = useState(null);
  const [bmAllData, setBmAllData] = useState(null);
  const [ramData, setRamData] = useState(null);
  const [ramAllData, setRamAllData] = useState(null);
  const [hddData, setHddData] = useState(null);
  const [hddAllData, setHddAllData] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  //  const [categoryValue, setCategoryValue] = useState('baremetal');
  const [updateData, setUpdateData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [statusDate, setStatusDate] = useState('');
  const [qty, setQty] = useState(0);
  const [addQty, setAddQty] = useState(0);
  const [itemStatus, setItemStatus] = useState(null);
  const [qtyOptionsList, setQtyOptionsList] = useState(null);
  const [availableOptionsList, setAvailableOptionsList] = useState(null);
  const [inUseOptionsList, setInUseOptionsList] = useState(null);
  const [locationList, setLocationList] = useState(null);
  const [locationsAll, setLocationsAll] = useState(null);
  const [tabActiveValue, setTabActiveValue] = useState('updateTabForm');
  const [addModal, setAddModal] = useState(false);
  const [addData, setAddData] = useState(null);
  const [stockDates, setStockDates] = useState({
    purchaseDate: null,
    warrantyDate: null,
    supportDate: null,
  });

  const [suppliersList, setSuppliersList] = useState(null);
  const [ownershipValue, setOwnershipValue] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');
  const [showDetails, setShowDetails] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  //  Other Consts
  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;
  const { TextArea } = Input;
  const [UpdateBaremetalForm] = Form.useForm();
  const [AddForm] = Form.useForm();
  const { TabPane } = Tabs;
  const { Panel } = Collapse;

  const pnStatus = {
    in_use: 'In Use',
    available: 'Available',
    in_repair: 'In Repair',
    damaged: 'Damaged',
    racked: 'Racked',
  };

  let updateContent;
  //  let updateForm;
  //  let bmPartArrayData;
  //  let avilableOptionsList;
  //  let inUseOptionsList;
  //  let pnAvailable;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Inventory</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Date on Change
  const StatusDateOnChange = (value, date) => {
    console.log('Status Date on change - ', value, date);
    setStatusDate(value.toISOString(true));
  };

  //  Update Modal On Cancel
  const handleCancel = () => {
    setUpdateModal(false);
    UpdateBaremetalForm.resetFields();
    setUpdateData(null);
    updateContent = null;
    setQty(0);
  };

  //  Add Modal On Cancel
  const handleAddModalCancel = () => {
    AddForm.resetFields();
    setAddModal(false);
    setAddData(null);
    setAddQty(0);
  };

  //  Delete Modal on Cancel
  const cancelModal = () => {
    setDeleteData(null);
    setDeleteModal(false);
  };

  //  Item Status Change (in use, available)
  const itemStatusTypeOnChange = (value) => {
    console.log('Item Status type on change - ', value);

    if (updateData.category === 'ram') {
      let itmQty;
      switch (value) {
        case pnStatus.available:
          itmQty = Array(Number(updateData.parts_available))
            .fill(null)
            .map((_, ind) => ({
              _id: ind + 1,
            }))
            .map((res) => (
              <Option key={res._id} value={res._id}>
                {res._id}
              </Option>
            ));

          setQtyOptionsList(itmQty);
          break;

        case pnStatus.in_use:
          itmQty = Array(Number(updateData.parts_in_use))
            .fill(null)
            .map((_, ind) => ({
              _id: ind + 1,
            }))
            .map((res) => (
              <Option key={res._id} value={res._id}>
                {res._id}
              </Option>
            ));

          setQtyOptionsList(itmQty);
          break;

        default:
          break;
      }
    }

    setItemStatus(value);
  };

  //  Quantity on change
  const qtyOnChange = (value) => {
    console.log('Item Status Quantity on change - ', value, qty);
    setQty(value);

    let bmPartArrayData;

    if (itemStatus) {
      switch (itemStatus) {
        case pnStatus.available:
          bmPartArrayData = Array(Number(value))
            .fill(null)
            .map(() => ({
              faulty_part_number: '',
            }));

          UpdateBaremetalForm.setFieldsValue({
            payload: bmPartArrayData,
          });
          break;

        case pnStatus.in_use:
          bmPartArrayData = Array(Number(value))
            .fill(null)
            .map(() => ({
              faulty_part_number: '',
              available_part_number: '',
            }));

          UpdateBaremetalForm.setFieldsValue({
            payload: bmPartArrayData,
          });
          break;

        default:
          break;
      }
    }

    const partNumbersArray = Array(Number(value))
      .fill(null)
      .map(() => ({
        part_number: '',
        mac_id: '',
      }));

    console.log('PartNumberArrys - ', partNumbersArray);
  };

  //  Add Quantity on Change
  const addQuantityOnChange = (e) => {
    console.log('Add quantity on change value - ', e.target.value);

    setAddQty(Number(e.target.value));

    let addQtyNumber;

    if (addData.category === 'baremetal') {
      addQtyNumber = Array(Number(e.target.value))
        .fill(null)
        .map(() => ({
          part_number: '',
          mac_id: '',
        }));
      AddForm.setFieldsValue({
        parts: addQtyNumber,
      });
    } else if (addData.category === 'hdd') {
      addQtyNumber = Array(Number(e.target.value))
        .fill(null)
        .map(() => ({
          part_number: '',
        }));
      AddForm.setFieldsValue({
        parts: addQtyNumber,
      });
    }
  };

  //  Update Button on Click
  const updateStockItem = (record, category) => {
    console.log('Update Stock record - ', category, record);

    //  Show Update Modal
    setUpdateModal(true);

    setTabActiveValue('updateTabForm');

    //  Set Update Date
    setUpdateData({
      ...record,
      category,
    });

    //  Check if category not RAM
    if (category !== 'ram') {
      //  Itme Quantity
      const itmQty = Array(Number(record.parts.length))
        .fill(null)
        .map((_, ind) => ({
          _id: ind + 1,
        }))
        .map((res) => (
          <Option key={res._id} value={res._id}>
            {res._id}
          </Option>
        ));

      setQtyOptionsList(itmQty);

      console.log('Compare itm Qty - ', itmQty);

      //  Set Available Options
      const availItms = record.parts
        .filter((itm) => itm.status === 'Available')
        .map((res) => (
          <Option
            key={res.part_number + Math.floor(Math.random() * 10 + 1)}
            value={res.part_number}
          >
            {res.part_number}
          </Option>
        ));
      setAvailableOptionsList(availItms);

      //  Set InUse Options
      const inUseItms = record.parts
        .filter((itm) => itm.status === 'In Use')
        .map((res) => (
          <Option
            key={res.part_number + Math.floor(Math.random() * 10 + 1)}
            value={res.part_number}
          >
            {res.part_number}
          </Option>
        ));
      setInUseOptionsList(inUseItms);
      //  Converting ISO Time to Normal Date
      UpdateBaremetalForm.setFieldsValue({
        ...record,
        name: category === 'baremetal' ? record.name : record.hdd,
        support_exp_date: moment(record.batches.support_exp_date).format(
          dateFormat,
        ),
        warranty_exp_date: moment(record.batches.warranty_exp_date).format(
          dateFormat,
        ),
        date: moment(),
      });
    } else if (category === 'ram') {
      //  Itme Quantity
      UpdateBaremetalForm.setFieldsValue({
        ...record,
        name: record.ram,
        support_exp_date: moment(record.batches.support_exp_date).format(
          dateFormat,
        ),
        warranty_exp_date: moment(record.batches.warranty_exp_date).format(
          dateFormat,
        ),
        date: moment(),
      });
    }

    console.log('Today date - ', moment().format(dateFormat));
  };

  //  Add Button on Click
  const addMoreStock = (record, category) => {
    console.log('Add on click - ', record, category);

    setTabActiveValue('addTabForm');
    setAddData({
      ...record,
      category,
    });

    setAddModal(true);

    if (category !== 'ram') {
      AddForm.setFieldsValue({
        ...record,
        name: category === 'baremetal' ? record.name : record.hdd,
        parts: [],
      });
      console.log('addData - ', addData);
    } else if (category === 'ram') {
      AddForm.setFieldsValue({
        ...record,
        name: record.ram,
        qty: '',
      });
    }
  };

  //  Delete Stock Item
  const deleteStockItem = (record, cat) => {
    console.log('Delete Item - ', record, cat);
    setDeleteData({
      ...record,
      category: cat,
    });
    setDeleteModal(true);
  };

  //  Show Details
  const showDetailsItem = (record, category) => {
    console.log('Details Modal - ', record, showDetails);
    setShowDetails({
      ...record,
      category,
    });
    setShowDetailsModal(true);
  };

  //  Cancel Show Details Modal
  const showDetailsCancelModal = () => {
    setShowDetailsModal(false);
    setShowDetails(null);
  };

  //  Edit Stock
  const editStock = (record, category) => {
    console.log('Edit Form record - ', category, record);

    dispatch({
      type: EDIT_FORM_DATA,
      payload: {
        editPageName: 'addStock',
        editFormData: {
          ...record,
          category,
        },
      },
    });

    LoadPage('addStock', dispatch);
  };

  //  Baremetal Table Columns
  const dataTableCols = [
    {
      title: 'SKU_ID',
      dataIndex: 'sku_id',
      key: 'sku_id',
    },
    {
      title: 'Item Name',
      dataIndex: 'name',
      key: 'name',
      className: 'col-main-title',
      render: (_, record) => (
        <>
          <Button
            type="link"
            htmlType="button"
            onClick={() => showDetailsItem(record, 'baremetal')}
          >
            {`${record.brand} ${record.model_no}`}
          </Button>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'qty',
      key: 'qty',
      render: (_, record) => <>{`${record.parts.length}`}</>,
    },
    {
      title: 'Available',
      dataIndex: 'parts_available',
      key: 'parts_available',
    },
    {
      title: 'Racked',
      dataIndex: 'parts_in_racked',
      key: 'parts_in_racked',
    },
    {
      title: 'In Use',
      dataIndex: 'parts_in_use',
      key: 'parts_in_use',
    },
    {
      title: 'Damaged',
      dataIndex: 'parts_damaged',
      key: 'parts_damaged',
    },
    {
      title: 'In Repair',
      dataIndex: 'parts_in_repair',
      key: 'parts_in_repair',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record) => (
        <div className="">
          <Button
            type="default"
            className="me-1"
            onClick={() => addMoreStock(record, 'baremetal')}
            icon={<IoAdd color="#777" />}
          />

          <Button
            type="default"
            onClick={() => updateStockItem(record, 'baremetal')}
            icon={<IoOptionsOutline color="#777" />}
          />

          <Button
            type="default"
            className="me-1 d-none"
            onClick={() => editStock(record, 'baremetal')}
            icon={<IoPencil color="#777" />}
          />
          {record.parts_in_use <= 0 ? (
            <Button
              type="default"
              className="ms-1"
              onClick={() => deleteStockItem(record, 'baremetal')}
              icon={<IoTrash color="#777" />}
            />
          ) : (
            <Tooltip title="Already in use, Can't be deleted" placement="left">
              <Button
                type="default"
                className="ms-1"
                onClick={() => deleteStockItem(record, 'baremetal')}
                icon={<IoTrash color="#777" />}
                disabled
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  //  RAM Table Cols
  const ramTableCols = [
    {
      title: 'SKU ID',
      dataIndex: 'sku_id',
      key: 'sku_id',
    },
    {
      title: 'RAM',
      dataIndex: 'ram',
      key: 'ram',
      width: 250,
      className: 'col-main-title',
      render: (_, record) => (
        <>
          <Button
            type="link"
            htmlType="button"
            onClick={() => showDetailsItem(record, 'ram')}
          >
            {`${record.ram}`}
          </Button>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => <>{`${record.qty}`}</>,
    },
    {
      title: 'Available',
      dataIndex: 'parts_available',
      key: 'parts_available',
    },
    {
      title: 'Racked',
      dataIndex: 'parts_in_racked',
      key: 'parts_in_racked',
    },
    {
      title: 'In Use',
      dataIndex: 'parts_in_use',
      key: 'parts_in_use',
    },
    {
      title: 'Damaged',
      dataIndex: 'parts_damaged',
      key: 'parts_damaged',
    },
    {
      title: 'In Repair',
      dataIndex: 'parts_in_repair',
      key: 'parts_in_repair',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      render: (id, record) => (
        <div className="">
          <Button
            type="default"
            className="me-1"
            onClick={() => addMoreStock(record, 'ram')}
            icon={<IoAdd color="#777" />}
          />

          <Button
            type="default"
            onClick={() => updateStockItem(record, 'ram')}
            icon={<IoOptionsOutline color="#777" />}
          />

          {record.parts_in_use <= 0 ? (
            <Button
              type="default"
              className="ms-1"
              onClick={() => deleteStockItem(record, 'ram')}
              icon={<IoTrash color="#777" />}
            />
          ) : (
            <Tooltip title="Already in use, Can't be deleted" placement="left">
              <Button
                type="default"
                className="ms-1"
                onClick={() => deleteStockItem(record, 'ram')}
                icon={<IoTrash color="#777" />}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  //  HDD Table Cols
  const hddTableCols = [
    {
      title: 'SKU ID',
      dataIndex: 'sku_id',
      key: 'sku_id',
    },
    {
      title: 'HDD',
      dataIndex: 'hdd',
      key: 'hdd',
      width: 150,
      render: (_, record) => (
        <>
          <Button
            type="link"
            htmlType="button"
            className="col-main-title"
            onClick={() => showDetailsItem(record, 'hdd')}
          >
            {`${record.hdd}`}
          </Button>
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => <>{`${record.parts.length}`}</>,
    },

    {
      title: 'Available',
      dataIndex: 'parts_available',
      key: 'parts_available',
    },
    {
      title: 'Racked',
      dataIndex: 'parts_in_racked',
      key: 'parts_in_racked',
    },
    {
      title: 'In Use',
      dataIndex: 'parts_in_use',
      key: 'parts_in_use',
    },
    {
      title: 'Damaged',
      dataIndex: 'parts_damaged',
      key: 'parts_damaged',
    },
    {
      title: 'In Repair',
      dataIndex: 'parts_in_repair',
      key: 'parts_in_repair',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      render: (id, record) => (
        <div className="">
          <Button
            type="default"
            className="me-1"
            onClick={() => addMoreStock(record, 'hdd')}
            icon={<IoAdd color="#777" />}
          />

          <Button
            type="default"
            onClick={() => updateStockItem(record, 'hdd')}
            icon={<IoOptionsOutline color="#777" />}
          />
          {record.parts_in_use <= 0 ? (
            <Button
              type="default"
              className="ms-1"
              onClick={() => deleteStockItem(record, 'hdd')}
              icon={<IoTrash color="#777" />}
            />
          ) : (
            <Tooltip title="Already in use, Can't be deleted" placement="left">
              <Button
                type="default"
                className="ms-1"
                onClick={() => deleteStockItem(record, 'hdd')}
                icon={<IoTrash color="#777" />}
                disabled
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  //  Get Baremetal Data
  const getBaremetalData = () => {
    GET('inventory/baremetal', state.token).then((res) => {
      if (res.success === true) {
        setBmData(res.data);
        setBmAllData(res.data);
      }
    });
  };

  //  Get RAM Data
  const getRamData = () => {
    GET('inventory/inv_rams', state.token).then((res) => {
      if (res.success === true) {
        setRamData(res.data);
        setRamAllData(res.data);
      }
    });
  };

  //  Get Baremetal Data
  const getHddData = () => {
    GET('inventory/hdds', state.token).then((res) => {
      if (res.success === true) {
        setHddData(res.data);
        setHddAllData(res.data);
      }
    });
  };

  //  Get Suppliers
  const getSuppliers = () => {
    GET('suppliers', state.token).then((res) => {
      if (res.success === true) {
        console.log('Suppliers data res - ', res.data);
        setSuppliersList(res.data);
      }
    });
  };

  //  Purchase Date on Change
  const PurchaseDateOnChange = (value) => {
    setStockDates({
      ...stockDates,
      purchaseDate: value.toISOString(true),
    });
    console.log('Purchase Expirty ', value, stockDates);
  };

  //  Warranty Expiry Date on Change
  const WarrantyExpiryDateOnChange = (value) => {
    setStockDates({
      ...stockDates,
      warrantyDate: value.toISOString(true),
    });
    console.log('Warranty Expirty ', value, stockDates);
  };

  //  Support Expiry Date on Change
  const SupportExpiryDateOnChange = (value) => {
    setStockDates({
      ...stockDates,
      supportDate: value.toISOString(true),
    });
    console.log('Support Expirty ', value, stockDates);
  };

  const deleteRow = () => {
    setDeleteModal(false);

    switch (deleteData.category) {
      case 'baremetal':
        DELETE('inventory/baremetal', deleteData._id, state.token).then(
          (res) => {
            setDeleteData(null);
            if (res.success === true) {
              setDeleteData(null);
              setUpdateData(null);
              notification.success({
                message: 'Deleted Successfully!',
                description: 'Data is Successfully Deleted!',
              });
              getBaremetalData();
              getRamData();
              getHddData();
            } else if (res.success === false) {
              notification.error({
                message: 'Something Went Wrong!',
                description: `Got Error with status code = ${res.status}!`,
              });
            }
          },
        );
        break;

      case 'hdd':
        DELETE('inventory/hdds', deleteData._id, state.token).then((res) => {
          setDeleteData(null);
          if (res.success === true) {
            setDeleteData(null);
            setUpdateData(null);
            notification.success({
              message: 'Deleted Successfully!',
              description: 'Data is Successfully Deleted!',
            });
            getBaremetalData();
            getRamData();
            getHddData();
          } else if (res.success === false) {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'ram':
        DELETE('inventory/inv_rams', deleteData._id, state.token).then(
          (res) => {
            setDeleteData(null);
            if (res.success === true) {
              setDeleteData(null);
              setUpdateData(null);
              notification.success({
                message: 'Deleted Successfully!',
                description: 'Data is Successfully Deleted!',
              });
              getBaremetalData();
              getRamData();
              getHddData();
            } else if (res.success === false) {
              notification.error({
                message: 'Something Went Wrong!',
                description: `Got Error with status code = ${res.status}!`,
              });
            }
          },
        );
        break;

      default:
        break;
    }
  };

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token)
      .then(async (res) => {
        //  setLoading(false);
        console.log('Get Locations res - ', res);
        if (res.success === true) {
          setLocationsAll(res.data.locations);
          const locationListArray = await res.data.locations.map((location) => (
            <Option key={location._id} value={`${location.name}`}>
              {`${location.name}, ${location.code}`}
            </Option>
          ));
          console.log('Location list after adding - ', locationListArray);
          setLocationList(locationListArray);
        } else if (res.success === false) {
          notification.error({
            message: 'Sorry we did not got Locations list from master data!!!',
          });
        }
      })
      .catch((err) => {
        //  setLoading(false);
        console.log('Get err - ', err);
      });
  };

  //  Supplier on change
  const supplierOnChange = async (value) => {
    console.log('Supplier on change - ', value);

    /* selectedSuplier.map((res) => (
      setCurrencyValue(res.currency);
    )); */
    console.log('Selected supplier - ', value.split('-')[1]);
    console.log('Location all - ', locationsAll);

    const baseCurrencySymbol = await locationsAll.filter(
      (itm) => itm.base_currency === value.split('-')[1],
    )[0];
    console.log('Supplier base currency symbol = ', baseCurrencySymbol);
    setCurrencyValue(
      baseCurrencySymbol ? baseCurrencySymbol.base_currency_symbol : '',
    );
    /* setBaseCurrency(baseCurrencySymbol ? baseCurrencySymbol.base_currency : ''); */

    //  setSupplierValue(date);
  };

  //  Ownership Type on change
  const ownershipOnChange = (e) => {
    console.log('Ownership on change value - ', e);
    setOwnershipValue(e.target.value);
  };

  //  Ownership Form
  let OwnershipLeaseForm;
  switch (ownershipValue) {
    case 'Owned':
      OwnershipLeaseForm = (
        <Row>
          <Col span="24">
            <Form.Item
              label="Owned Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please enter Owned Price',
                },
              ]}
            >
              <Input
                placeholder="Enter price"
                type="number"
                addonBefore={currencyValue ? currencyValue.split('-')[0] : ''}
                className="ps-1"
              />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    case 'Leased':
      OwnershipLeaseForm = (
        <Row>
          <Col span="24">
            <Form.Item
              label="Leased Price"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please enter Leased Price',
                },
              ]}
            >
              <Input
                placeholder="Enter price"
                addonBefore={currencyValue ? currencyValue.split('-')[0] : ''}
              />
            </Form.Item>
          </Col>
        </Row>
      );
      break;

    default:
      break;
  }

  //  Location on Change
  const locationOnChange = (value) => {
    if (value === 'all') {
      setBmData(bmAllData);
      setRamData(ramAllData);
      setHddData(hddAllData);
    } else {
      //  Baremetal
      GET(`inventory/baremetal?location=${value}`, state.token).then((res) => {
        if (res.success === true) {
          setBmData(res.data);
        } else {
          setBmData(null);
        }
      });

      //  HDD
      GET(`inventory/hdds?location=${value}`, state.token).then((res) => {
        if (res.success === true) {
          setHddData(res.data);
        } else {
          setHddData(null);
        }
      });

      //  RAM
      GET(`inventory/inv_rams?location=${value}`, state.token)
        .then((res) => {
          if (res.success === true) {
            setRamData(res.data);
          } else {
            setRamData(null);
          }
        })
        .catch((err) => notification.error({
          message: 'Something went wrong',
          description: `Something went wrong with status code = ${err.status_code}`,
        }));
    }
  };

  //  Update Form Success
  const UpdateBMBasicSuccess = (values) => {
    console.log('Update Form Success - ', values);

    setUpdateModal(false);

    let payload;

    switch (updateData.category) {
      case 'baremetal':
        payload = {
          action: values.status,
          payload: values.payload,
          date: statusDate,
        };
        POST(
          `inventory/baremetal/${updateData._id}/faulty`,
          state.token,
          payload,
        ).then((res) => {
          if (res.success === true) {
            UpdateBaremetalForm.resetFields();
            setUpdateData(null);
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getBaremetalData();
            getRamData();
            getHddData();
            getLocations();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'hdd':
        payload = {
          action: values.status,
          payload: values.payload,
          date: statusDate,
        };
        POST(
          `inventory/hdds/${updateData._id}/faulty`,
          state.token,
          payload,
        ).then((res) => {
          if (res.success === true) {
            UpdateBaremetalForm.resetFields();
            setUpdateData(null);
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getBaremetalData();
            getRamData();
            getHddData();
            getLocations();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'ram':
        payload = {
          /* location: updateData.location,
          currency: updateData.currency,
          sku_id: updateData.sku_id,
          ram: updateData.ram,
          parts_available: values.parts_available,
          parts_damaged: values.parts_damaged,
          parts_in_use: values.parts_in_use,
          parts_in_repair: values.parts_in_repair,
          parts_in_racked: values.parts_in_racked,
          master_id: updateData.master_id,
          batches: updateData.batches,
          qty: updateData.qty, */
          from_pool: values.status_type,
          to_pool: values.status,
          value: values.update_qty,
        };
        POST(
          `inventory/inv_rams/${updateData._id}/update`,
          state.token, payload,
        ).then(
          (res) => {
            if (res.success === true) {
              UpdateBaremetalForm.resetFields();
              setUpdateData(null);
              notification.success({
                message: 'Successfully Updated!',
                description: 'Data is Successfully Updated!',
              });
              getBaremetalData();
              getRamData();
              getHddData();
              getLocations();
            } else {
              notification.error({
                message: 'Something Went Wrong!',
                description: `Got Error with status code = ${res.status}!`,
              });
            }
          },
        );
        break;

      default:
        break;
    }
  };

  //  Add Stock Form Success
  const addFormSuccess = (values) => {
    console.log('Add Form Success values - ', values);
    setAddModal(false);

    let addFormPayload;
    switch (addData.category) {
      case 'baremetal':
        addFormPayload = {
          batch: {
            purchase_date: values.purchase_date,
            purchase_order: values.purchase_order,
            warranty_exp_date: values.warranty_exp_date,
            support_exp_date: values.support_exp_date,
            product_type: values.product_type,
            ownership_type: values.ownership_type,
            price: values.price,
            supplier: values.supplier,
          },
          parts: values.parts,
        };
        console.log('Baremetal Add Stock Body - ', addFormPayload);
        POST(
          `inventory/baremetal/${addData._id}/stock`,
          state.token,
          addFormPayload,
        ).then((res) => {
          if (res.success === true) {
            UpdateBaremetalForm.resetFields();
            AddForm.resetFields();
            setAddData(null);
            setUpdateData(null);
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getBaremetalData();
            getRamData();
            getHddData();
            getLocations();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'hdd':
        addFormPayload = {
          batch: {
            purchase_date: values.purchase_date,
            purchase_order: values.purchase_order,
            warranty_exp_date: values.warranty_exp_date,
            support_exp_date: values.support_exp_date,
            product_type: values.product_type,
            ownership_type: values.ownership_type,
            price: values.price,
            supplier: values.supplier,
          },
          parts: values.parts,
        };
        console.log('HDDs Add Stock Body - ', addFormPayload);
        POST(
          `inventory/hdds/${addData._id}/stock`,
          state.token,
          addFormPayload,
        ).then((res) => {
          if (res.success === true) {
            UpdateBaremetalForm.resetFields();
            AddForm.resetFields();
            setAddData(null);
            setUpdateData(null);
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getBaremetalData();
            getRamData();
            getHddData();
            getLocations();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });
        break;

      case 'ram':
        addFormPayload = {
          batch: {
            purchase_order: values.purchase_order,
            purchase_date: stockDates.purchaseDate,
            warranty_exp_date: stockDates.warrantyDate,
            support_exp_date: stockDates.supportDate,
            product_type: 'Brand New',
            ownership_type: 'Owned',
            price: values.price,
            supplier: values.supplier,
            qty: values.add_qty,
          },
        };
        console.log('Add Ram Body - ', addFormPayload);
        POST(
          `inventory/inv_rams/${addData._id}/stock`,
          state.token,
          addFormPayload,
        ).then((res) => {
          if (res.success === true) {
            UpdateBaremetalForm.resetFields();
            AddForm.resetFields();
            setAddData(null);
            setUpdateData(null);
            notification.success({
              message: 'Successfully Updated!',
              description: 'Data is Successfully Updated!',
            });
            getBaremetalData();
            getRamData();
            getHddData();
            getLocations();
          } else {
            notification.error({
              message: 'Something Went Wrong!',
              description: `Got Error with status code = ${res.status}!`,
            });
          }
        });

        break;

      default:
        break;
    }
  };

  //  Show Full Details
  const showFullDetails = () => {
    if (showDetails) {
      switch (showDetails.category) {
        case 'baremetal':
          return (
            <Row>
              <Col span="24" className="">
                <Tabs defaultActiveKey="basicInfo">
                  <TabPane tab="Basic Info" key="basicInfo">
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Location:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.location : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Processor:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.name : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Processor Quantity:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.processor_qty : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>SKU ID:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.sku_id : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Brand:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.brand : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Model#:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.model_no : ''}
                        </Col>
                      </Row>

                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Par Level:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.par_level : ''}
                        </Col>
                      </Row>
                    </Col>
                  </TabPane>
                  <TabPane tab="RAM" key="ram">
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Ram Type:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.ram.ram_type : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>RAM Slots:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.ram.max_slots : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Max RAM Size Per Slot:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.ram.max_memory_per_slot
                            : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Speed:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.ram.max_speed : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>RAM ECC:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.ram.ecc.toString() : ''}
                        </Col>
                      </Row>
                    </Col>
                  </TabPane>
                  <TabPane tab="HDD Disk" key="hddDisk">
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>HDD Protocol:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.hdd.protocol.map((res) => (
                              <span>
                                {res}
                                ,
                                {' '}
                              </span>
                            ))
                            : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>HDD Form Factor:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.hdd.form_factor.map((res) => (
                              <span>
                                {res}
                                ,
                                {' '}
                              </span>
                            ))
                            : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Disk type:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.hdd.disk_type.map((res) => (
                              <span>
                                {res}
                                ,
                                {' '}
                              </span>
                            ))
                            : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Max number of HDD Supported:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.hdd.max_slots : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Dual Power Supply:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.others.dual_power.toString()
                            : ''}
                        </Col>
                      </Row>
                    </Col>
                  </TabPane>
                  <TabPane tab="Others" key="others">
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Max Lan Cards Supported:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.others.max_lan_supported
                            : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Lan Card:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.others.lan_brand : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Lan Card Inserted:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.others.lan_inserted : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>RAID Card:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.others.raid : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Optical Drive:</em>
                        </Col>
                        <Col span="12">
                          {showDetails
                            ? showDetails.others.optical_drive.toString()
                            : ''}
                        </Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Quantity:</em>
                        </Col>
                        <Col span="12">
                          {showDetails ? showDetails.parts.length : ''}
                        </Col>
                      </Row>
                    </Col>
                  </TabPane>
                  <TabPane
                    tab={
                      showDetails ? (
                        <Badge count={showDetails.batches.length}>
                          Batches &nbsp;&nbsp;
                        </Badge>
                      ) : (
                        'Batches'
                      )
                    }
                    key="batches"
                  >
                    <Collapse accordion>
                      {showDetails.batches && showDetails.batches.length > 0
                        ? showDetails.batches.map((res, index) => (
                          <Panel
                            header={`${index + 1}. ${res.id}`}
                            key={res.id}
                          >
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Supplier:</em>
                              </Col>
                              <Col span="12">
                                {res.supplier ? res.supplier : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>PO#:</em>
                              </Col>
                              <Col span="12">
                                {res.purchase_order
                                  ? res.purchase_order
                                  : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Purchase Date:</em>
                              </Col>
                              <Col span="12">
                                {res.purchase_date
                                  ? res.purchase_date
                                  : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Warranty Expiry Date:</em>
                              </Col>
                              <Col span="12">
                                {res.warranty_exp_date
                                  ? res.warranty_exp_date
                                  : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Support Expiry Date:</em>
                              </Col>
                              <Col span="12">
                                {res.support_exp_date
                                  ? res.support_exp_date
                                  : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Product Type:</em>
                              </Col>
                              <Col span="12">
                                {res.product_type ? res.product_type : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Ownership Type:</em>
                              </Col>
                              <Col span="12">
                                {res.ownership_type
                                  ? res.ownership_type
                                  : 'N/A'}
                              </Col>
                            </Row>
                            <Row className="border-bottom p-1">
                              <Col span="12">
                                <em>Price:</em>
                              </Col>
                              <Col span="12">
                                {res.price ? res.price : 'N/A'}
                              </Col>
                            </Row>
                          </Panel>
                        ))
                        : ''}
                    </Collapse>
                  </TabPane>
                  <TabPane
                    tab={
                      showDetails ? (
                        <Badge count={showDetails.parts.length}>
                          Part Numbers &nbsp;&nbsp;
                        </Badge>
                      ) : (
                        'Part Numbers'
                      )
                    }
                    key="partNumbers"
                  >
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="2">#</Col>
                        <Col span="8">
                          <strong>Part Numbers</strong>
                        </Col>
                        <Col span="7">
                          <strong>Status</strong>
                        </Col>
                        <Col span="7">
                          <strong>Batch</strong>
                        </Col>
                      </Row>
                      {showDetails
                        ? showDetails.parts.map((res, ind) => (
                          <Row className="border-bottom p-1">
                            <Col span="2">{ind + 1}</Col>
                            <Col span="8">{res.part_number}</Col>
                            <Col span="7">{res.status}</Col>
                            <Col span="7">{res.batch}</Col>
                          </Row>
                        ))
                        : ''}
                    </Col>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          );

        case 'ram':
          return (
            <Row>
              <Col span="24" className="">
                <Tabs defaultActiveKey="ramBasicInfo">
                  <TabPane tab="Basic Info" key="ramBasicInfo">
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Location:</em>
                        </Col>
                        <Col span="12">{showDetails.location}</Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>SKU ID:</em>
                        </Col>
                        <Col span="12">{showDetails.sku_id}</Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Quantity:</em>
                        </Col>
                        <Col span="12">{showDetails.qty}</Col>
                      </Row>
                      {/* <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>In Use:</em>
                        </Col>
                        <Col span="12">{showDetails.parts_in_use}</Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Racked:</em>
                        </Col>
                        <Col span="12">{showDetails.parts_in_racked}</Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>In Repair:</em>
                        </Col>
                        <Col span="12">{showDetails.parts_in_repair}</Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Damaged:</em>
                        </Col>
                        <Col span="12">{showDetails.parts_damaged}</Col>
                      </Row> */}
                    </Col>
                  </TabPane>
                  <TabPane
                    tab={
                      showDetails ? (
                        <Badge count={showDetails.batches.length}>
                          Batches &nbsp;&nbsp;
                        </Badge>
                      ) : (
                        'Batches'
                      )
                    }
                    key="ramBatches"
                  >
                    <Col span="24" className="border">
                      <Collapse accordion>
                        {showDetails.batches && showDetails.batches.length > 0
                          ? showDetails.batches.map((res, index) => (
                            <Panel
                              header={`${index + 1}. ${res.id}`}
                              key={res.id}
                            >
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Supplier:</em>
                                </Col>
                                <Col span="12">
                                  {res.supplier ? res.supplier : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>PO#:</em>
                                </Col>
                                <Col span="12">
                                  {res.purchase_order
                                    ? res.purchase_order
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Purchase Date:</em>
                                </Col>
                                <Col span="12">
                                  {res.purchase_date
                                    ? res.purchase_date
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Warranty Expiry Date:</em>
                                </Col>
                                <Col span="12">
                                  {res.warranty_exp_date
                                    ? res.warranty_exp_date
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Support Expiry Date:</em>
                                </Col>
                                <Col span="12">
                                  {res.support_exp_date
                                    ? res.support_exp_date
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Price per unit:</em>
                                </Col>
                                <Col span="12">
                                  {res.price ? res.price : 'N/A'}
                                </Col>
                              </Row>
                            </Panel>
                          ))
                          : ''}
                      </Collapse>
                    </Col>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          );

        case 'hdd':
          return (
            <Row>
              <Col span="24" className="">
                <Tabs defaultActiveKey="hddBasicInfo">
                  <TabPane tab="Basic Info" key="hddBasicInfo">
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>Location:</em>
                        </Col>
                        <Col span="12">{showDetails.location}</Col>
                      </Row>
                      <Row className="border-bottom p-1">
                        <Col span="12">
                          <em>SKU ID:</em>
                        </Col>
                        <Col span="12">{showDetails.sku_id}</Col>
                      </Row>
                    </Col>
                  </TabPane>
                  <TabPane
                    tab={
                      showDetails ? (
                        <Badge count={showDetails.batches.length}>
                          Batches &nbsp;&nbsp;
                        </Badge>
                      ) : (
                        'Batches'
                      )
                    }
                    key="hddBatches"
                  >
                    <Col span="24" className="border">
                      <Collapse accordion>
                        {showDetails.batches && showDetails.batches.length > 0
                          ? showDetails.batches.map((res, index) => (
                            <Panel
                              header={`${index + 1}. ${res.id}`}
                              key={res.id}
                            >
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Supplier:</em>
                                </Col>
                                <Col span="12">
                                  {res.supplier ? res.supplier : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>PO#:</em>
                                </Col>
                                <Col span="12">
                                  {res.purchase_order
                                    ? res.purchase_order
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Purchase Date:</em>
                                </Col>
                                <Col span="12">
                                  {res.purchase_date
                                    ? res.purchase_date
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Warranty Expiry Date:</em>
                                </Col>
                                <Col span="12">
                                  {res.warranty_exp_date
                                    ? res.warranty_exp_date
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Support Expiry Date:</em>
                                </Col>
                                <Col span="12">
                                  {res.support_exp_date
                                    ? res.support_exp_date
                                    : 'N/A'}
                                </Col>
                              </Row>
                              <Row className="border-bottom p-1">
                                <Col span="12">
                                  <em>Price per unit:</em>
                                </Col>
                                <Col span="12">
                                  {res.price ? res.price : 'N/A'}
                                </Col>
                              </Row>
                            </Panel>
                          ))
                          : ''}
                      </Collapse>
                    </Col>
                  </TabPane>
                  <TabPane
                    tab={
                      showDetails ? (
                        <Badge count={showDetails.parts.length}>
                          Part Numbers &nbsp;&nbsp;
                        </Badge>
                      ) : (
                        'Part Numbers'
                      )
                    }
                    key="hddPartNumbers"
                  >
                    <Col span="24" className="border">
                      <Row className="border-bottom p-1">
                        <Col span="2">#</Col>
                        <Col span="8">
                          <strong>Part Numbers</strong>
                        </Col>
                        <Col span="7">
                          <strong>Status</strong>
                        </Col>
                        <Col span="7">
                          <strong>Batch</strong>
                        </Col>
                      </Row>
                      {showDetails
                        ? showDetails.parts.map((res, ind) => (
                          <Row className="border-bottom p-1">
                            <Col span="2">{ind + 1}</Col>
                            <Col span="8">{res.part_number}</Col>
                            <Col span="7">{res.status}</Col>
                            <Col span="7">{res.batch}</Col>
                          </Row>
                        ))
                        : ''}
                    </Col>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          );

        default:
          return 'No Data Found';
      }
    }
    return true;
  };

  useEffect(() => {
    //  Get Data
    getBaremetalData();
    getRamData();
    getHddData();
    getLocations();
    getSuppliers();

    //  Update Data Log
    console.log(
      'Update Content - ',
      updateContent,
      'Update Data - ',
      updateData,
    );

    //  Bradcrumb Nav
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, [updateContent]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              All Inventory
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter="20" className="m-2">
        <Col span="24">
          <Row gutter={30}>
            <Col span="12">
              <Form.Item label="Select Location" className="m-0">
                <Select
                  showSearch
                  placeholder="Select Location"
                  optionFilterProp="children"
                  className="w-100"
                  onChange={locationOnChange}
                  defaultValue="all"
                >
                  <Option value="all">All</Option>
                  {locationList}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24" className="mt-2">
          {/* {TableWithData} */}
          <Tabs defaultActiveKey="baremetal">
            <TabPane tab="Baremetal" key="baremetal">
              <Table columns={dataTableCols} dataSource={bmData} />
            </TabPane>
            <TabPane tab="HDD" key="hdd">
              <Table columns={hddTableCols} dataSource={hddData} />
            </TabPane>
            <TabPane tab="RAM" key="ram">
              <Table columns={ramTableCols} dataSource={ramData} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      {/* Update Item Status Starts */}
      <Modal
        title={
          updateData && updateData.sku_id
            ? `Stock movement - ${updateData.category.toUpperCase()} / ${updateData.sku_id}`
            : 'Update'
        }
        visible={updateModal}
        onCancel={handleCancel}
        width={800}
        footer={false}
        destroyOnClose
      >
        <>
          <Form
            form={UpdateBaremetalForm}
            name="bmBasic"
            layout="vertical"
            onFinish={UpdateBMBasicSuccess}
            autoComplete="off"
          >
            <Tabs
              defaultActiveKey="updateTabForm"
              activeKey={tabActiveValue}
              onTabClick={(key) => setTabActiveValue(key)}
            >
              <TabPane tab="Update Form" key="updateTabForm">
                <Row gutter="30">
                  <Col span="12">
                    <Form.Item label="Location" name="location">
                      <Input placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item label="Item Name" name="name">
                      <Input placeholder="" disabled />
                    </Form.Item>
                  </Col>

                  <Col span="12">
                    <Form.Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Date',
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        className="w-100"
                        onChange={StatusDateOnChange}
                      />
                    </Form.Item>
                  </Col>

                  <Col span="12">
                    <Form.Item
                      label="Item Status Type"
                      name="status_type"
                      rules={[
                        {
                          required: true,
                          message: 'Please select Item status From',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Item Status"
                        optionFilterProp="children"
                        onChange={itemStatusTypeOnChange}
                      >
                        <Option value={pnStatus.available}>
                          Available
                        </Option>
                        <Option value={pnStatus.in_use}>In use</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {
                    updateData && updateData.category === 'ram' ? (
                      <Col span="12">
                        <Form.Item
                          label="Item Status"
                          name="status"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Item status',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Status"
                            optionFilterProp="children"
                          >
                            <Option value="Racked">Racked</Option>
                            <Option value="Damaged">
                              Damaged
                            </Option>
                            <Option value="In Repair">
                              In Repair
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    ) : (
                      <Col span="12">
                        <Form.Item
                          label="Item Status"
                          name="status"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Item status',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Status"
                            optionFilterProp="children"
                          >
                            <Option value="racked_parts">Racked</Option>
                            <Option value="replace_faulty_parts">
                              Damaged
                            </Option>
                            <Option value="repair_faulty_parts">
                              In Repair
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )
                  }

                  {itemStatus ? (
                    <Col span="12">
                      <Form.Item
                        label="Item Quantity"
                        name="update_qty"
                        rules={[
                          {
                            required: true,
                            message: 'Please select Quantity',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Item Quantity"
                          optionFilterProp="children"
                          onChange={qtyOnChange}
                        >
                          {qtyOptionsList}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : (
                    ''
                  )}

                  {/*  {updateData && updateData.category === 'ram' ? (
                    <>
                      <Col span="12">
                        <Form.Item
                          label="Parts Available Qty"
                          name="parts_available"
                          initialValue={0}
                        >
                          <InputNumber
                            placeholder="Enter Part available quantity"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                      <Col span="12">
                        <Form.Item
                          label="Parts In Racked Qty"
                          name="parts_in_racked"
                          initialValue={0}
                        >
                          <InputNumber
                            placeholder="Enter Part In Racked quantity"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                      <Col span="12">
                        <Form.Item
                          label="Parts In Use Qty"
                          name="parts_in_use"
                          initialValue={0}
                        >
                          <InputNumber
                            placeholder="Enter Part In Use quantity"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                      <Col span="12">
                        <Form.Item
                          label="Parts In Repair Qty"
                          name="parts_in_repair"
                          initialValue={0}
                        >
                          <InputNumber
                            placeholder="Enter Part In Repair quantity"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                      <Col span="12">
                        <Form.Item
                          label="Parts In Damaged Qty"
                          name="parts_damaged"
                          initialValue={0}
                        >
                          <InputNumber
                            placeholder="Enter Part Damaged quantity"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    ''
                  )} */}

                  <Col span="24">
                    <Form.Item label="Remarks/Comments" name="remarksComments">
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col span="24">
                    {updateData && updateData.category === 'ram' ? (
                      <Button
                        type="primary"
                        size="large"
                        className="me-1 btn-success px-4"
                        htmlType="submit"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        size="large"
                        className="me-1 btn-success px-4"
                        htmlType="button"
                        onClick={() => setTabActiveValue('partNumbers')}
                      >
                        Proceed Part Numbers
                      </Button>
                    )}
                  </Col>
                </Row>
              </TabPane>
              {updateData && updateData.category !== 'ram' ? (
                <TabPane
                  tab={(
                    <Badge count={qty > 0 ? Number(qty) : ''}>
                      Part Numbers &nbsp;&nbsp;
                    </Badge>
                  )}
                  key="partNumbers"
                >
                  <Row gutter="30">
                    <Col span="24">
                      <Row gutter={30}>
                        {itemStatus && itemStatus === pnStatus.available ? (
                          <Form.List name="payload">
                            {(fields) => (
                              <>
                                {fields.map(({ key, name, fieldKey }, ind) => (
                                  <Col span="12" key={key}>
                                    <Form.Item
                                      label={`Part Number ${ind + 1}`}
                                      name={[name, 'faulty_part_number']}
                                      fieldKey={[
                                        fieldKey,
                                        'faulty_part_number',
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please select Part Number',
                                        },
                                      ]}
                                    >
                                      <Select
                                        showSearch
                                        placeholder="Select Part Number"
                                        optionFilterProp="children"
                                      >
                                        {availableOptionsList}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                ))}
                              </>
                            )}
                          </Form.List>
                        ) : (
                          <Form.List name="payload">
                            {(fields) => (
                              <>
                                {fields.map(({ key, name, fieldKey }, ind) => (
                                  <>
                                    <Col span="12" key={key}>
                                      <Form.Item
                                        label={`Part Number ${ind + 1}`}
                                        name={[name, 'faulty_part_number']}
                                        fieldKey={[
                                          fieldKey,
                                          'faulty_part_number',
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              'Please select Part Number',
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          placeholder="Select Part Number"
                                          optionFilterProp="children"
                                          filterOption={(input, option) => option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0}
                                        >
                                          {inUseOptionsList}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span="12" key={key}>
                                      <Form.Item
                                        label={`Replace Part Number ${ind + 1}`}
                                        name={[name, 'available_part_number']}
                                        fieldKey={[
                                          fieldKey,
                                          'available_part_number',
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              'Please select Replace Part Number',
                                          },
                                        ]}
                                      >
                                        <Select
                                          showSearch
                                          placeholder="Select Part Number"
                                          optionFilterProp="children"
                                        >
                                          {availableOptionsList}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </>
                                ))}
                              </>
                            )}
                          </Form.List>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter="30" className="border-top pt-2 mt-2">
                    <Col span="24">
                      <Button
                        type="default"
                        size="large"
                        className="me-1 px-2"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="me-1 btn-success px-4"
                        htmlType="submit"
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
              ) : (
                ''
              )}
            </Tabs>
          </Form>
        </>
      </Modal>

      {/* Add Stock */}
      <Modal
        title={
          addData && addData.sku_id
            ? `Add Stock - ${addData.sku_id}`
            : 'Add Stock'
        }
        visible={addModal}
        onCancel={handleAddModalCancel}
        width={800}
        footer={false}
        destroyOnClose
      >
        <>
          <Form
            form={AddForm}
            name="bmBasic"
            layout="vertical"
            onFinish={addFormSuccess}
            autoComplete="off"
          >
            <Tabs
              defaultActiveKey="updateTabForm"
              activeKey={tabActiveValue}
              onTabClick={(key) => setTabActiveValue(key)}
            >
              <TabPane tab="Add Stock" key="addTabForm">
                <Row gutter="30">
                  <Col span="12">
                    <Form.Item label="Location" name="location">
                      <Input placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item label="Item Name" name="name">
                      <Input placeholder="" disabled />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item
                      label="Supplier"
                      name="supplier"
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Supplier',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Supplier "
                        optionFilterProp="children"
                        onChange={supplierOnChange}
                        filterOption={(input, option) => option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0}
                      >
                        {suppliersList
                          ? suppliersList.map((res) => (
                            <Option
                              key={res._id}
                              value={`${res.name}-${res.currency}`}
                            >
                              {res.name}
                            </Option>
                          ))
                          : ''}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item
                      label="Purchase Order#"
                      name="purchase_order"
                      rules={[
                        {
                          required: true,
                          message: 'Please Enter Purchase order',
                        },
                      ]}
                    >
                      <Input placeholder="Please enter Purchase order" />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item label="Purchase Date" name="purchase_date">
                      <DatePicker
                        format={dateFormat}
                        className="w-100"
                        onChange={PurchaseDateOnChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item
                      label="Warranty Expiry Date"
                      name="warranty_exp_date"
                    >
                      <DatePicker
                        format={dateFormat}
                        className="w-100"
                        onChange={WarrantyExpiryDateOnChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item
                      label="Support Expiry Date"
                      name="support_exp_date"
                    >
                      <DatePicker
                        format={dateFormat}
                        className="w-100"
                        onChange={SupportExpiryDateOnChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="12">
                    <Form.Item
                      label="Item Quantity"
                      name="add_qty"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter Quantity',
                        },
                      ]}
                    >
                      <InputNumber
                        onBlur={addQuantityOnChange}
                        placeholder="Enter Quantity"
                        className="w-100"
                      />
                    </Form.Item>
                  </Col>
                  {addData && addData.category === 'baremetal' ? (
                    <>
                      <Col span="12">
                        <Form.Item
                          label="Product Type"
                          name="product_type"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Product Type',
                            },
                          ]}
                          initialValue="Brand New"
                        >
                          <Radio.Group>
                            <Radio value="Brand New">Brand New</Radio>
                            <Radio value="Refurbished">Refurbished</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span="12">
                        <Form.Item
                          label="Ownership Type (per month)"
                          name="ownership_type"
                          rules={[
                            {
                              required: true,
                              message: 'Please select Ownership',
                            },
                          ]}
                        >
                          <Radio.Group onChange={ownershipOnChange}>
                            <Radio value="Owned">Owned</Radio>
                            <Radio value="Leased">Leased</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col span="12">{OwnershipLeaseForm}</Col>
                    </>
                  ) : (
                    <Col span="12">
                      <Form.Item
                        label="Each Unit Price"
                        name="price"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Each Unit Price',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter price"
                          addonBefore={
                            currencyValue ? currencyValue.split('-')[0] : ''
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col span="24">
                    {addData && addData.category === 'ram' ? (
                      <Button
                        type="primary"
                        size="large"
                        className="me-1 btn-success px-4"
                        htmlType="submit"
                      >
                        Add More Stock
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        size="large"
                        className="me-1 btn-success px-4"
                        htmlType="button"
                        onClick={() => setTabActiveValue('addPartNumbers')}
                      >
                        Proceed Part Numbers
                      </Button>
                    )}
                  </Col>
                </Row>
              </TabPane>
              {addData && addData.category !== 'ram' ? (
                <TabPane
                  tab={(
                    <Badge count={addQty > 0 ? Number(addQty) : ''}>
                      Part Numbers &nbsp;&nbsp;
                    </Badge>
                  )}
                  key="addPartNumbers"
                >
                  <Row gutter="30">
                    <Col span="24">
                      {addData && addData.category === 'baremetal' ? (
                        <Row gutter={30}>
                          <Form.List name="parts">
                            {(fields) => (
                              <>
                                {fields.map(({ key, name, fieldKey }, ind) => (
                                  <>
                                    <Col span="12" key={key}>
                                      <Form.Item
                                        label={`Part Number - ${ind + 1}`}
                                        name={[name, 'part_number']}
                                        fieldKey={[fieldKey, 'part_number']}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              'Please select Part Number',
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Enter Part Number..." />
                                      </Form.Item>
                                    </Col>
                                    <Col span="12" key={key}>
                                      <Form.Item
                                        label={`Mac ID - ${ind + 1}`}
                                        name={[name, 'mac_id']}
                                        fieldKey={[fieldKey, 'mac_id']}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              'Please select Replace Part Number',
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Enter Mac ID..." />
                                      </Form.Item>
                                      <Form.Item
                                        label={`Mac ID - ${ind + 1}`}
                                        name={[name, 'status']}
                                        fieldKey={[fieldKey, 'status']}
                                        initialValue="Available"
                                        className="d-none"
                                      >
                                        <Input placeholder="Enter Mac ID..." />
                                      </Form.Item>
                                      <Form.Item
                                        label={`Mac ID - ${ind + 1}`}
                                        name={[name, 'batch']}
                                        fieldKey={[fieldKey, 'batch']}
                                        initialValue=""
                                        className="d-none"
                                      >
                                        <Input placeholder="Enter Mac ID..." />
                                      </Form.Item>
                                    </Col>
                                  </>
                                ))}
                              </>
                            )}
                          </Form.List>
                        </Row>
                      ) : (
                        ''
                      )}
                      {addData && addData.category === 'hdd' ? (
                        <Row gutter={30}>
                          <Form.List name="parts">
                            {(fields) => (
                              <>
                                {fields.map(({ key, name, fieldKey }, ind) => (
                                  <Col span="12" key={key}>
                                    <Form.Item
                                      label={`Part Number - ${ind + 1}`}
                                      name={[name, 'part_number']}
                                      fieldKey={[fieldKey, 'part_number']}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please select Part Number',
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter Part Number..." />
                                    </Form.Item>
                                    <Form.Item
                                      label={`Status ${ind + 1}`}
                                      name={[name, 'status']}
                                      fieldKey={[fieldKey, 'status']}
                                      initialValue="Available"
                                      className="d-none"
                                    >
                                      <Input placeholder="Enter Mac ID..." />
                                    </Form.Item>
                                    <Form.Item
                                      label={`Mac ID - ${ind + 1}`}
                                      name={[name, 'batch']}
                                      fieldKey={[fieldKey, 'batch']}
                                      initialValue=""
                                      className="d-none"
                                    >
                                      <Input placeholder="Enter Mac ID..." />
                                    </Form.Item>
                                  </Col>
                                ))}
                              </>
                            )}
                          </Form.List>
                        </Row>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                  <Row gutter="30" className="border-top pt-2 mt-2">
                    <Col span="24">
                      <Button
                        type="default"
                        size="large"
                        className="me-1 px-2"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        className="me-1 btn-success px-4"
                        htmlType="submit"
                      >
                        Add Stock
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
              ) : (
                ''
              )}
            </Tabs>
          </Form>
        </>
      </Modal>

      {/* Show Full Details */}
      <Modal
        title={
          showDetails
            ? `Showing Details of - ${showDetails.sku_id}`
            : 'Show Details'
        }
        visible={showDetailsModal}
        onCancel={showDetailsCancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        footer={null}
        width={700}
      >
        {showFullDetails()}
      </Modal>

      {/* Delete confirmation */}
      <Modal
        title="Confirm if you really want to delete?"
        visible={deleteModal}
        onCancel={cancelModal}
        okText="Delete"
        okButtonProps={{ className: 'btn-danger' }}
        onOk={deleteRow}
      >
        <Row>
          <Col span="24">
            <p>
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 500,
                  color: 'var(--bs-danger)',
                }}
              >
                {deleteData ? deleteData.sku_id : ''}
              </span>
            </p>
            <p>Do you really want to delete above ?</p>
            <p />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ListInventory;

import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  notification,
  Breadcrumb,
  Button,
  Tag,
  //  Popover,
  //  List,
  Modal,
  Select,
  Form,
} from 'antd';
import moment from 'moment';

//  Custom imports
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import { GET, POST } from '../../common/crud';
import LoadPage from '../../common/loadPage';
import { dateFormat } from '../../common/common';

const NewOrders = () => {
  const [data, setData] = useState();
  const [allData, setAllData] = useState(null);
  const [stopModal, setStopModal] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(null);

  const [state, dispatch] = useContext(AppContext);
  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>All Location</Breadcrumb.Item>
    </Breadcrumb>
  );

  const paymentStatus = (value) => {
    switch (value) {
      case 'paid':
        return (
          <Tag color="green" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'pending':
        return (
          <Tag color="red" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'partial':
        return (
          <Tag color="orange" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'cancelled':
        return (
          <Tag color="magenta" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      default:
        return <Tag>{value}</Tag>;
    }
  };

  const orderStatus = (value) => {
    switch (value) {
      case 'received':
        return (
          <Tag color="green" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'idle':
        return (
          <Tag color="orange" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      default:
        return <Tag>{value}</Tag>;
    }
  };

  /* const orderCols = [
    {
      title: 'Customer Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Order Date',
      dataIndex: 'order_date',
      key: 'order_date',
      width: 120,
      render: (_, record) => (
        <>
          {
           moment(record.order_date).format(dateFormat)
          }
        </>
      ),
    },
    {
      title: 'Or. Status ',
      dataIndex: 'order_status',
      key: 'order_status',
      width: 150,
      render: (_, record) => (
        <>
          {orderStatus(record.order_status)}
        </>
      ),
    },
    {
      title: 'Disable on ',
      dataIndex: 'disable_on',
      key: 'disable_on',
      width: 120,
      render: (_, record) => (
        <>
          {
           moment(record.invoice_date).format(dateFormat)
          }
        </>
      ),
    },

    {
      title: 'Payment Status ',
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: 150,
      render: (_, record) => (
        <>
          {paymentStatus(record.payment_status)}
        </>
      ),
    },
  ]; */

  const goToCustomerDetail = (customerId) => {
    console.log('Go to detai value - ', customerId);
    sessionStorage.setItem('customerId', JSON.stringify(customerId));
    //  sessionStorage.setItem('customerId', '');
    LoadPage('customerDetail', dispatch);
  };

  const goToOrderDetail = (id) => {
    console.log('Go to order details - ', id);
    sessionStorage.setItem('orderId', JSON.stringify(id));
    LoadPage('orderDetail', dispatch);
  };

  //  Show Cancel Order Popup
  /* const orderCancel = (record) => {
    setStopModal(true);
    setCancelOrder(record);
  }; */

  const orderCols = [
    {
      title: 'Date',
      dataIndex: 'last_invoice',
      key: 'last_invoice',
      width: 125,
      render: (_, record) => (
        <>{moment(record.last_invoice).format(dateFormat)}</>
      ),
    },
    {
      title: 'Orders#',
      dataIndex: 'orders',
      key: 'orders',
      render: (_, record) => (
        <Button
          type="link"
          className="p-0"
          onClick={() => goToOrderDetail(record._id)}
        >
          {record._id.slice(-6)}
        </Button>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (_, record) => (
        <>
          <Button
            type="link"
            className="p-0"
            onClick={() => goToCustomerDetail(record.customer_id)}
          >
            {record.display_name}
          </Button>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: 'email-truncate text-truncate',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: 150,
    },

    /*
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      width: 150,
    },

    {
      title: 'Next Inv.',
      dataIndex: 'next_invoice',
      key: 'next_invoice',
      width: 160,
      render: (_, record) => (
        <>
          {
           moment(record.last_invoice).add(30, 'd').format(dateFormat)
          }
        </>
      ),
    }, */
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      className: 'text-end',
      width: '150px',
      render: (text) => (
        <>{text !== 'NA' && text !== 'N/A' ? <>{text}</> : { text }}</>
      ),
    },
    {
      title: 'Ord. Status ',
      dataIndex: 'order_status',
      key: 'order_status',
      width: 150,
      render: (_, record) => <>{orderStatus(record.order_status)}</>,
    },
    {
      title: 'Payment',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (_, record) => <>{paymentStatus(record.payment_status)}</>,
    },
    /* {
      title: 'Ord. Action',
      dataIndex: 'order_type',
      key: 'order_type',
      render: (_, record) => (
        <>
          {record.order_type === 'single' ? (
            <Button
              size="small"
              className="btn-info text-white"
              onClick={() => orderCancel(record)}
            >
              Resume
            </Button>
          ) : (
            <Button
              size="small"
              className="btn-default"
              onClick={() => orderCancel(record)}
            >
              Cancel
            </Button>
          )}
        </>
      ),
    }, */
  ];

  //  Payment Status on change
  const paymentStatusOnChange = (value) => {
    if (value !== 'all') {
      const filData = allData.filter((itm) => itm.payment_status === value);
      setData(filData);
    } else if (value === 'all') {
      setData(allData);
    }
  };

  const getAllOrders = (firstOrder) => {
    GET(`orders?new_orders=${firstOrder}`, state.token).then((res) => {
      console.log('All orders res - ', res);
      if (res.success === true) {
        setData(res.data);
        setAllData(res.data);
      } else {
        notification.error({
          message: 'Something Wrong!',
          description: `Something went wrong with status code = ${res.status}`,
        });
      }
    });
  };

  //  Cancel Order Success
  const cancelOrdreSuccess = () => {
    const stop = cancelOrder.order_type !== 'single';

    POST(
      `orders/recurring/${cancelOrder._id}?stop=${stop}`,
      state.token,
      {},
    ).then((res) => {
      if (res.success === true) {
        setStopModal(false);
        setCancelOrder(null);
        getAllOrders();
        notification.success({
          message: 'Successfully your order cancelled!',
          description: 'Your order is cancelled successfully!!!',
        });
      }
    });
  };

  //  Modal Cancel
  const handleCancel = () => {
    setStopModal(false);
  };

  useEffect(() => {
    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    getAllOrders(true);

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });

      //  Remove Order Id from Session Storage
      sessionStorage.removeItem('ordreId');
      sessionStorage.removeItem('customerId');
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Manage Orders
            </Col>
            {/* <Col span="10" flex="row" align="end" className="text-end">
              <Button
                type="primary"
                onClick={() => LoadPage('addLocation', dispatch)}
              >
                <IoAdd />
                {' '}
                Add Location
              </Button>
            </Col> */}
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Row gutter={30}>
            <Col span="8">
              <Form.Item
                label="Payment Status:"
                initialValue="all"
              >
                <Select
                  showSearch
                  placeholder="Select Payment Status"
                  optionFilterProp="children"
                  className="w-100"
                  onChange={paymentStatusOnChange}
                  defaultValue="all"
                >
                  <Option value="all">All</Option>
                  <Option value="paid_init">Paid</Option>
                  <Option value="pending">Pending</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span="24">
          <Table columns={orderCols} dataSource={data} />
        </Col>
      </Row>

      <Modal
        title="Cancel Order"
        visible={stopModal}
        onCancel={handleCancel}
        okText={
          cancelOrder && cancelOrder.order_type === 'single'
            ? ' Yes Resume order '
            : 'Yes Stop order '
        }
        okButtonProps={{
          className: 'btn-primary',
          title:
            cancelOrder && cancelOrder.order_type === 'single'
              ? ' Resume order '
              : 'Stop Recurring order ',
        }}
        onOk={() => cancelOrdreSuccess()}
      >
        <Row>
          <Col style={{ fontSize: '20px' }}>
            Dear
            {' '}
            {cancelOrder ? cancelOrder.display_name : null}
            , are you sure,
            do you really want to
            {' '}
            <span className="text-danger">
              {cancelOrder && cancelOrder.order_type === 'single'
                ? ' Continue with '
                : 'Stop Order'}
              {' '}
              {cancelOrder ? cancelOrder._id.slice(-6) : null}
              {' '}
              from Next Month
            </span>
            . Once abandoned by system, this order cannot be revoked, you need
            to again create new order!
          </Col>
        </Row>
        <Row />
      </Modal>
    </>
  );
};

export default NewOrders;

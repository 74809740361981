import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Tooltip,
  notification,
  Modal,
  Form,
  Input,
} from 'antd';

import {
  IoCheckmarkCircle,
  //  IoAlertCircle,
  //  IoBan,
  //  IoCheckmark,
} from 'react-icons/io5';

import PropTypes from 'prop-types';

//  Custome imports
import AppContext from '../context/appContext';
import { GET, POST, PUT } from './crud';

const GST = ({ customer }) => {
  const [custDetails, setCustDetails] = useState(null);
  const [gstModal, setGstModal] = useState(false);
  const [gstData, setGstData] = useState(null);

  const [gstForm] = Form.useForm();

  const [state] = useContext(AppContext);

  //  GST Verification Checking
  const checkGSTVerification = (values) => {
    console.log('GST Verification - ', values);
    POST(`gstin/${values.gstin}`, state.token, null).then((res) => {
      console.log('GST Verification res - ', res);
      if (res.success === true) {
        setGstData(res.data);
        setGstModal(true);
      } else {
        notification.error({
          message: 'GST Verification Error!',
          description: `${res.error}`,
        });
      }
    });
  };

  //  GST Verified
  const verifiedGst = () => {
    setGstModal(false);
    const verifyPayload = {
      ...custDetails,
      gstin: gstData ? gstData.gstin : '',
      gst_treatment: 'Registered',
      gstin_verified: true,
      //  kyc_verified: 'verified',
    };
    PUT('customers', custDetails._id, state.token, verifyPayload).then((res) => {
      if (res.success === true) {
        GET(`customers/${custDetails._id}`, state.token).then((resCust) => {
          if (resCust.success === true) {
            console.log('Customers res - ', resCust.data);
            //  setCustomers(res.data);
            setCustDetails(resCust.data);
            gstForm.setFieldsValue({
              gstin: resCust.data.gstin,
            });
            notification.success({
              message: 'Successfully GST Verified!',
              description: 'User GST is successfully Verified!',
            });
          } else {
            notification.error({
              message: 'Something Wrong!!!',
              description: 'Something went wrong, did not get data!',
            });
          }
        });
      } else {
        notification.error({
          message: 'Something went wrong!',
          description: 'Something went wrong!!',
        });
      }
    });
  };

  //  Gst Modal Cancel
  const gstHandleCancel = () => {
    setGstModal(false);
  };

  useEffect(() => {
    console.log('GST got customer details - ', customer);

    if (customer && customer.gstin) {
      gstForm.setFieldsValue({
        gstin: customer.gstin,
      });
      setCustDetails(customer);

      console.log('GST number - ', customer.gstin);
    }
  }, []);

  return (
    <>
      {
        custDetails && custDetails.gstin_verified ? (
          <>
            <Tooltip title="Verified">
              <IoCheckmarkCircle
                color="var(--bs-success)"
                size="18"
                style={{ marginRight: '5px' }}
              />
            </Tooltip>
            {custDetails.gstin}
          </>
        ) : (

          <Form
            form={gstForm}
            name="basic"
            layout="vertical"
            onFinish={checkGSTVerification}
            autoComplete="off"
            initialValue={
              custDetails && 'gstin' in custDetails ? custDetails.gstin : ''
            }
          >
            <Row>
              <Col span="17">
                <Form.Item
                  label=""
                  name="gstin"
                  rules={[
                    {
                      required: true,
                      message: 'Enter GST Number min. 15 characters',
                      min: 15,
                    },
                  ]}
                  className="mb-0"

                >
                  <Input placeholder="Enter GST number" />
                </Form.Item>
              </Col>
              <Col span="7">
                <Button type="primary" htmlType="submit" className="">Verify</Button>
              </Col>
            </Row>
          </Form>

        )
      }

      {/* GST Verification Modal */}
      <Modal
        title={
          gstData ? `GSTIN - ${gstData.gstin}` : 'GSTIN'
        }
        visible={gstModal}
        onCancel={gstHandleCancel}
        okText="Verified"
        okButtonProps={{
          size: 'large',
        }}
        cancelButtonProps={{
          size: 'large',
        }}
        onOk={verifiedGst}
        width={900}
      >
        <Row>
          <Col style={{ }} className="border gstInfo">
            <Row className="border-bottom border-gray" align="center">
              <Col span="8" />
              <Col span="8">
                <strong>GST Information</strong>
              </Col>
              <Col span="8">
                <strong>Customer Information</strong>
              </Col>
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Legal Name:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.legalName : 'N/A'}
              </Col>
              <Col span="8">
                {custDetails ? custDetails.display_name : 'N/A'}
              </Col>
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>State Name:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.prinicalPlaceOfBusiness.addr.stcd : 'N/A'}
              </Col>
              <Col span="8">
                {custDetails && custDetails.billing_address && custDetails.billing_address.state ? custDetails.billing_address.state : 'N/A'}
              </Col>
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>District Name:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.prinicalPlaceOfBusiness.addr.dst : 'N/A'}
              </Col>
              <Col span="8">
                {custDetails && custDetails.billing_address && custDetails.billing_address.city ? custDetails.billing_address.city : 'N/A'}
              </Col>
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Pincode:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.prinicalPlaceOfBusiness.addr.pncd : 'N/A'}
              </Col>
              <Col span="8">
                {custDetails && custDetails.billing_address && custDetails.billing_address.postal_code ? custDetails.billing_address.postal_code : 'N/A'}
              </Col>
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Address:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? `
                  ${gstData.prinicalPlaceOfBusiness.addr.bnm}, ${gstData.prinicalPlaceOfBusiness.addr.loc}, ${gstData.prinicalPlaceOfBusiness.addr.st}, ${gstData.prinicalPlaceOfBusiness.addr.bno},  ${gstData.prinicalPlaceOfBusiness.addr.flno}, ${gstData.prinicalPlaceOfBusiness.addr.stcd}, ${gstData.prinicalPlaceOfBusiness.addr.dst}, ${gstData.prinicalPlaceOfBusiness.addr.city},, ${gstData.prinicalPlaceOfBusiness.addr.pncd}
                ` : 'N/A'}
              </Col>
              <Col span="8">
                {custDetails && custDetails.billing_address ? `
                  ${custDetails.billing_address.line1}, ${custDetails.billing_address.line2}, ${custDetails.billing_address.city}, ${custDetails.billing_address.state}, ${custDetails.billing_address.postal_code}, ${custDetails.billing_address.country}
                ` : 'N/A'}
              </Col>
            </Row>
            <Row>
              <Col span="24" className="otherOptionsSubHead">
                <span><strong>Other Options:</strong></span>
              </Col>
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Status:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.status : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>State Jurisdiction:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.stateJurisdiction : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Center Jurisdiction:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.centreJurisdiction : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Registration Date:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.registrationDate : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Taxpayer Type:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.taxpayerType : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Constitution:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.constitution : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
            <Row className="border-bottom border-gray" align="center">
              <Col span="8">
                <em>Nature of Business:</em>
              </Col>
              <Col span="8" style={{ backgroundColor: '#efffef' }}>
                { gstData ? gstData.prinicalPlaceOfBusiness.ntr : 'N/A'}
              </Col>
              <Col span="8" />
            </Row>
          </Col>
        </Row>
        <Row />
      </Modal>
    </>
  );
};

GST.propTypes = {
  customer: PropTypes.arrayOf(
    PropTypes.shape({
      gstin_verified: PropTypes.bool,
      gstin: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

export default GST;

import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Tooltip,
  Tag,
} from 'antd';
import moment from 'moment';

import {
  IoCheckmarkCircle,
  IoAlertCircle,
} from 'react-icons/io5';
import { FaCloudDownloadAlt } from 'react-icons/fa';

// Custom imports
import './orderDetail.scss';
import AppContext from '../../context/appContext';
import { BREADCRUMB } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { GET } from '../../common/crud';
import GST from '../../common/gst';
import { dateFormat } from '../../common/common';

const InvoiceDetail = () => {
  const [state, dispatch] = useContext(AppContext);
  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [custDetails, setCustDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [showGST, setShowGST] = useState(false);

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('/', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allInvoices', dispatch)}
        >
          All Invoices
        </Button>
      </Breadcrumb.Item>

      <Breadcrumb.Item>Invoice Detail</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Payment Status
  const paymentStatus = (value) => {
    switch (value) {
      case 'paid':
        return (
          <Tag color="green" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'pending':
        return (
          <Tag color="red" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'partial':
        return (
          <Tag color="orange" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      case 'cancelled':
        return (
          <Tag color="magenta" style={{ fontSize: '15px' }}>
            {value}
          </Tag>
        );

      default:
        return (
          <Tag>
            {value}
          </Tag>
        );
    }
  };

  //  Addon Title based on Category
  const addonTitle = (addonInfo) => {
    const addonCat = 'category' in addonInfo ? addonInfo.category : '';
    switch (addonCat) {
      case 'os':
        return (
          `${addonInfo.title}`
        );

      case 'ram':
        return (
          `${addonInfo.technology}/${addonInfo.size}GB`
        );

      case 'hdd':
        return (
          `${addonInfo.protocol}/${addonInfo.capacity}GB`
        );

      case 'bandwidth':
        return (
          'Bandwidth'
        );

      case 'ipaddress':
        return (
          'IP Address'
        );

      case 'raid':
        if ('raid' in addonInfo) {
          return (addonInfo.raid);
        }
        return ('N/A');

      default:
        return 'N/A';
    }
  };

  //  Go to customer detail
  const goToCustomerDetail = (id) => {
    console.log('Csutomer id - ', id);
    sessionStorage.setItem('customerId', JSON.stringify(id));
    LoadPage('customerDetail', dispatch);
  };

  //  Get Invoice Details
  const getInvoiceDetails = (id) => {
    console.log('Got Invoice id in function - ', id);

    GET(`invoices/${id}`, state.token).then((res) => {
      if (res.success === true) {
        console.log('Invoice details res - ', res.data);
        setInvoiceDetails(res.data);

        //  Get Customers Details
        GET(`customers/${res.data.customer_id}`, state.token).then((custRes) => {
          if (custRes.success === true) {
            console.log('Customer details res - ', custRes.data);
            setCustDetails(custRes.data);
            setShowGST(true);
          }
        });

        //  Get Order Details
        GET(`orders/${res.data.order_id}`, state.token).then((ordRes) => {
          if (ordRes.success === true) {
            setOrderDetails(ordRes.data);
            console.log('Order details res - ', ordRes.data);
          }
        });
      }
    });
  };

  useEffect(() => {
    // Bredcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    const invId = sessionStorage.getItem('invoiceId');

    if (invId) {
      setInvoiceId(JSON.parse(invId));
      console.log('invoice id - ', JSON.parse(invId));

      //  Get All Details
      getInvoiceDetails(JSON.parse(invId));
    }

    console.log('sdfsafd', invoiceId);

    return () => {
      // Bredcrumb
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });

      sessionStorage.removeItem('invoiceId');
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Invoice Detail
            </Col>
            <Col span="10" className="text-end">
              <Button type="default" size="large" className="btn-success me-2">
                <FaCloudDownloadAlt className="me-1" color="#fff" />
                {' '}
                Download Invoice
              </Button>
              {
                invoiceDetails && invoiceDetails.payment_status !== 'paid' ? (
                  <Button type="primary" size="large" className="btn-success px-3">
                    Pay Now
                    {' '}
                    {
                  invoiceDetails ? Number(invoiceDetails.amount_paid).toFixed(2) : 'N/A'
                }
                  </Button>
                ) : ''
              }

            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24 mb-2">
          <Row span="40">
            <Col span="11">
              {custDetails ? (
                <ul className="list-unstyled full-width customer-details-list">
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">Company Name:</span>
                      <span className="each-customer-details">
                        <Button type="link" className="p-0" onClick={() => goToCustomerDetail(custDetails._id)}>
                          {custDetails ? custDetails.display_name : 'N/A'}
                        </Button>

                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">Customer Name:</span>
                      <span className="each-customer-details">
                        {custDetails
                          ? `${custDetails.first_name} ${custDetails.last_name}`
                          : 'N/A'}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">Email:</span>
                      {custDetails && custDetails.email_verified ? (
                        <span className="each-customer-details">
                          <Tooltip title="Verified">
                            <IoCheckmarkCircle
                              color="var(--bs-success)"
                              size="18"
                              style={{ marginRight: '5px' }}
                            />
                          </Tooltip>
                          {custDetails.email}
                        </span>
                      ) : (
                        <span className="each-customer-details">
                          <Tooltip title="Verified">
                            <IoAlertCircle
                              color="var(--bs-orange)"
                              size="18"
                              style={{ marginRight: '5px' }}
                            />
                          </Tooltip>
                          {custDetails && custDetails.email ? custDetails.email : 'N/A'}
                        </span>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">Phone:</span>
                      <span className="each-customer-details">
                        <Tooltip title="Not Verified">
                          <IoAlertCircle color="var(--bs-warning)" size="18" />
                        </Tooltip>
                        +91 12345 12345
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">GST:</span>
                      <span className="each-customer-details">
                        {showGST
                          ? <GST customer={custDetails || ''} /> : ''}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">IP Address:</span>
                      <span className="each-customer-details">000.000.000</span>
                    </div>
                  </li>
                  <li>
                    <div className="each-customer-detail full-width">
                      <span className="each-customer-title">
                        KYC Verification:
                      </span>
                      <span className="each-customer-details">
                        {
                       custDetails ? custDetails.kyc_verified : 'N/A'
                      }
                        {/* {
                        <Tooltip title="Ban User">
                          <Button
                            type="default"
                            size="small"
                            htmlType="button"
                            className="ms-1 btn-outline-danger"
                            onClick={() => banUser()}>
                            <IoBan />
                          </Button>
                        </Tooltip>
                      } */}
                      </span>
                    </div>
                  </li>
                </ul>
              ) : ''}
            </Col>

            <Col span="9" offset="4">
              {
                invoiceDetails ? (
                  <ul className="list-unstyled full-width customer-details-list">
                    <li>
                      <div className="each-customer-detail full-width">
                        <span className="each-customer-title">Invoice Nos.:</span>
                        <span className="each-customer-details">
                          #
                          {
                          invoiceDetails ? invoiceDetails._id.substr(invoiceDetails._id.length - 5) : 'N/A'
                        }
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="each-customer-detail full-width">
                        <span className="each-customer-title">Invoice Date:</span>
                        <span className="each-customer-details">
                          { invoiceDetails
                            ? moment(invoiceDetails.invoice_date).format(dateFormat) : 'N/A'}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="each-customer-detail full-width">
                        <span className="each-customer-title">Payment Status</span>
                        <span className="each-customer-details">
                          { invoiceDetails
                            ? (
                              paymentStatus(invoiceDetails.payment_status)
                            ) : 'N/A'}
                        </span>
                      </div>
                    </li>
                  </ul>
                ) : ''
              }
            </Col>
          </Row>
        </Col>
        <Col span="24">

          <div className="ant-table">
            <div className="ant-table-container">
              <div className="ant-table-content">
                <table
                  className="w-100 table-striped order-detail-table"
                  style={{ tableLayout: 'auto' }}
                >
                  <thead className="ant-table-thead">
                    <tr>
                      <th className="ant-table-cell">Item Description</th>
                      <th className="ant-table-cell" width="70">
                        Qty.
                      </th>
                      <th className="ant-table-cell" width="120">
                        Item Price/Unit
                      </th>
                      {/* <th className="ant-table-cell text-end" width="100">
                        Discount
                      </th> */}
                      <th className="ant-table-cell text-end" width="150">
                        Tax
                      </th>
                      <th className="ant-table-cell text-end" width="130">
                        Sub Total
                      </th>

                    </tr>
                  </thead>
                  <tbody className="ant-table-tbody">
                    {orderDetails
                      ? orderDetails.cart_items.map((res, index) => (
                        <>
                          <tr>
                            <td className="ant-table-cell">
                              <strong>
                                {index + 1}
                                .
                                {' '}
                                {res.product_info.title}
                              </strong>
                            </td>
                            <td className="ant-table-cell">{res.quantity}</td>
                            <td className="ant-table-cell text-end">
                              {/* 'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {' '}
                              {Number(
                                100
                                    * (res.product_price
                                      / (100 + res.product_tax)),
                                    ).toFixed(2) */}
                              {'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {Number(res.product_price).toFixed(2)}
                            </td>
                            {/* <td className="ant-table-cell text-end">
                              N/A
                            </td> */}
                            <td className="ant-table-cell text-end">
                              {'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {' '}
                              {Number(
                                res.product_tds,
                              ).toFixed(2)}
                            </td>
                            <td className="ant-table-cell text-end">
                              {'base_currency_symbol' in res.product_info
                                ? res.product_info.base_currency_symbol
                                : ''}
                              {' '}
                              {res.product_price * res.quantity}
                            </td>

                          </tr>
                          {'addons' in res && res.addons.length > 0
                            ? res.addons.map((add) => (
                              <tr>
                                <td className="ant-table-cell ps-3">
                                  {addonTitle(add.addon_info)}
                                </td>
                                <td className="ant-table-cell">
                                  {add.quantity}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {'base_currency_symbol' in add.addon_info
                                    ? add.addon_info.base_currency_symbol
                                    : ''}
                                  {' '}
                                  {Number(add.addon_info.price).toFixed(2)}
                                </td>
                                {/* <td className="ant-table-cell text-end">
                                    N/A
                                  </td> */}
                                <td className="ant-table-cell text-end">
                                  {'base_currency_symbol' in add.addon_info
                                    ? add.addon_info.base_currency_symbol
                                    : ''}
                                  {' '}
                                  { Number(Number(add.addon_info.price_with_tds)
                                    - Number(add.addon_info.price)).toFixed(2)}
                                </td>
                                <td className="ant-table-cell text-end">
                                  {'base_currency_symbol' in add.addon_info
                                    ? add.addon_info.base_currency_symbol
                                    : ''}
                                  {' '}
                                  {Number(add.addon_info.price_with_tds).toFixed(2)}
                                </td>

                              </tr>
                            ))
                            : ''}
                        </>
                      ))
                      : ''}
                    <tr>
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end" />
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end">Discount:</td>
                      <td className="ant-table-cell text-end">
                        {orderDetails ? `${orderDetails.cart_items[0].symbol} ${Number(orderDetails.discount_value).toFixed(2)}` : 'N/A'}
                      </td>
                      <td className="ant-table-cell" />
                    </tr>
                    <tr>
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end" />
                      <td className="ant-table-cell" />
                      <td className="ant-table-cell text-end">
                        <strong>Grand Total:</strong>
                        :
                      </td>
                      <td className="ant-table-cell text-end">
                        {orderDetails ? `${orderDetails.cart_items[0].symbol} ${Number(orderDetails.cart_price).toFixed(2)}` : 'N/A'}
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </Col>
        <Col span="24" className="mt-2">
          <div className="full-width mb-2"><strong>Offline payment Bank details</strong></div>
          <div className="full-width">
            Account Name: IBEE SOFTWARE SOLUTIONS PRIVATE LIMITED
            {' '}
            <br />
            Account No.: 020205001561
            <br />
            Bank Name: ICICI Bank
            <br />
            IFSC Code: ICIC0000202
            <br />
            Branch: Punjagutta, Hyderabad
            <br />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InvoiceDetail;

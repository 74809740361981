import React, { useState, useContext, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Form,
  Select,
  Switch,
  notification,
  Input,
} from 'antd';

// Custom imports
import AppContext from '../../../context/appContext';
import { BREADCRUMB } from '../../../context/types';
import LoadPage from '../../../common/loadPage';
import { POST } from '../../../common/crud';

const AddRAM = () => {
  const [state, dispatch] = useContext(AppContext);
  const [ramSizeValue, setRamSizeValue] = useState(null);
  const [ramEccValue, setRamEccValue] = useState(false);
  /* const [ramSpeedValue, setRamSpeedValue] = useState(null);
   */

  const { Option } = Select;

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button type="link" className="p-0" onClick={() => LoadPage('allRams', dispatch)}>
          All RAMs
        </Button>

      </Breadcrumb.Item>
      <Breadcrumb.Item>Add RAM</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create RAM SUCCESS
  const createNewRAMSuccess = async (values) => {
    console.log('Add RAM Values - ', values);

    const addRamBody = {
      make: values.make,
      model: values.model,
      size: Number(values.size),
      speed: values.speed,
      technology: values.technology,
      ram_type: values.ram_type,
      ecc: values.ecc,
    };

    POST('masterdata/rams', state.token, addRamBody).then((res) => {
      console.log('Add Ram POST res - ', res);
      if (res.success === true) {
        LoadPage('allRams', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Data is Successfully Added!',
        });
      } else if (res.success === false) {
        notification.error({
          message: 'Something Went Wrong!',
          description: `Got Error with status code = ${res.status}!`,
        });
      }
    }).catch((err) => {
      console.log('Add Ram POST err - ', err);
    });
  };

  // Currency onChange
  const currencyOnChange = (value) => {
    console.log('Currency selected on change - ', value);
  };

  //  Ram Size onChange
  const ramSizeOnChange = (value) => {
    setRamSizeValue(value);
  };

  //  Ram ECC onChange
  const ramEccOnChange = (value) => {
    setRamEccValue(value);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add RAM
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter="20" className="m-2">
        <Col span="24">
          <Form
            name="basic"
            layout="vertical"
            onFinish={createNewRAMSuccess}
            autoComplete="off"
            initialValues={{
              size: ramSizeValue,
              speed: '',
              technology: '',
              ram_type: '',
              ecc: ramEccValue,
            }}
          >
            <Row gutter="30" className="flex-direction-column">
              <Col span="12">
                <Form.Item
                  label="RAM Make"
                  name="make"
                  rules={[
                    { required: true, message: 'Enter RAM make' },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select disk make"
                    optionLabelProp="children"
                  >
                    <Option value="Micron">Micron</Option>
                    <Option value="Corsair">Corsair</Option>
                    <Option value="Kingston">Kingston</Option>
                    <Option value="Samsung">Samsung</Option>
                    <Option value="Crucial">Crucial</Option>
                    <Option value="Transcend">Transcend</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="RAM Model"
                  name="model"
                >
                  <Input placeholder="Enter RAM model" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Row>
                  <Col span="21">
                    <Form.Item
                      label="RAM Size"
                      name="size"
                      rules={[
                        {
                          required: true,
                          message: 'Please select RAM Size',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select RAM Size"
                        optionFilterProp="children"
                        onChange={ramSizeOnChange}
                      >
                        <Option value="4">4</Option>
                        <Option value="8">8</Option>
                        <Option value="16">16</Option>
                        <Option value="32">32</Option>
                        <Option value="64">64</Option>
                        <Option value="128">128</Option>
                        <Option value="256">256</Option>
                        <Option value="512">512</Option>
                      </Select>

                    </Form.Item>
                  </Col>
                  <Col
                    span="3"
                    className="d-inline-flex bg-light border align-items-center justify-content-center"
                    style={{ height: '32px', marginTop: '30px' }}
                  >
                    GB
                  </Col>
                </Row>

              </Col>
              <Col span="12">
                <Form.Item
                  label="RAM Speed"
                  name="speed"
                  rules={[
                    {
                      required: true,
                      message: 'Enter RAM Speed',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select RAM Speed"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="800 MHz">800 MHz</Option>
                    <Option value="1333 MHz">1333 MHz</Option>
                    <Option value="1600 MHz">1600 MHz</Option>
                    <Option value="2400 MHz">2400 MHz</Option>
                    <Option value="2666 MHz">2666 MHz</Option>
                    <Option value="2933 MHz">2933 MHz</Option>
                    <Option value="3000 MHz">3000 MHz</Option>
                    <Option value="3200 MHz">3200 MHz</Option>
                    <Option value="3600 MHz">3600 MHz</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="RAM Technology"
                  name="technology"
                  rules={[
                    {
                      required: true,
                      message: 'Select RAM Technology',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select RAM Technology"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="DDR2">DDR2</Option>
                    <Option value="DDR3">DDR3</Option>
                    <Option value="DDR4">DDR4</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="RAM Type"
                  name="ram_type"
                  rules={[
                    {
                      required: true,
                      message: 'Enter RAM Type',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select RAM Type"
                    optionFilterProp="children"
                    onChange={currencyOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    <Option value="UDIMM">UDIMM</Option>
                    <Option value="RDIMM">RDIMM</Option>
                    997
                    <Option value="LRDIMM">LRDIMM</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="RAM ECC"
                  name="ecc"
                  rules={[
                    {
                      required: true,
                      message: 'Enter RAM ECC',
                    },
                  ]}
                >
                  <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={ramEccOnChange} />
                </Form.Item>
              </Col>

              <Col span="24" className="my-1 py-2 border-top">
                <Button type="default" size="large" className="me-1 px-2">
                  Cancel
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="me-1 btn-success px-4"
                  htmlType="submit"
                >
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddRAM;

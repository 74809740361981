import React, { useEffect, useState, useContext } from 'react';
import {
  Row,
  Col,
  Form,
  Select,
  Tabs,
  Input,
  Radio,
  DatePicker,
  Switch,
  Button,
  Badge,
  message,
  Breadcrumb,
  notification,
  InputNumber,
  Alert,
} from 'antd';
//  import moment from 'moment';
import moment from 'moment';

//  Custom imports
import './addStock.scss';
import AppContext from '../../context/appContext';
import { BREADCRUMB, EDIT_FORM_DATA } from '../../context/types';
import LoadPage from '../../common/loadPage';
import { GET, POST } from '../../common/crud';
import { dateFormat } from '../../common/common';

const AddStock = () => {
  const [state, dispatch] = useContext(AppContext);

  //  Values
  const [categoryValue, setCategoryValue] = useState('');

  const [qtyValue, setQtyValue] = useState(1);
  const [ownershipValue, setOwnershipValue] = useState('');
  const [partCountValue, setPartCountValue] = useState([]);
  const [currencyValue, setCurrencyValue] = useState('');
  const [locationValue, setLocationtValue] = useState(state.editFormData ? state.editFormData.location : '');
  const [baseCurrency, setBaseCurrency] = useState(null);
  //  const [currencyList, setCurrencyList] = useState();
  const [locationList, setLocationList] = useState();
  const [locationsAll, setLocationsAll] = useState(null);
  const [suppliersList, setSuppliersList] = useState();
  //  const [supplierData, setSupplierData] = useState();
  const [processorList, setProcessorList] = useState();
  const [hddList, setHddList] = useState();
  const [ramList, setRamList] = useState();
  const [tabActiveValue, setTabActiveValue] = useState();
  const [, setTabsToggle] = useState({
    tab1: false,
    tab2: true,
    tab3: true,
    tab4: true,
  });
  /* const [hddTabsToggle, setHddTabsToggle] = useState({
    tab1: false,
    tab2: true,
  }); */
  const [purchaseDateValue, setPurchaseDateValue] = useState();
  const [supportDateValue, setSupportDateValue] = useState();
  const [warrantyDateValue, setWarrantyDateValue] = useState();
  const [bmPartArray, setBmPartArray] = useState([]);
  const [ramEccValue, setRamEccValue] = useState(false);
  const [pricing, setPricing] = useState(0);
  //  const [ramSelected, setRamSelected] = useState(null);
  const [ramData, setRamData] = useState(null);
  //  const [hddSelected, setHddSelected] = useState(null);
  const [hddData, setHddData] = useState(null);
  const [errors, setErrors] = useState(null);

  const { Option } = Select;
  const { TabPane } = Tabs;

  const [BMBasicInfoForm] = Form.useForm();

  const [BMRamForm] = Form.useForm();

  const [BMHddForm] = Form.useForm();

  const [BMOthersForm] = Form.useForm();

  const [HDDFormBlock] = Form.useForm();

  const [HDDPartNumbersForm] = Form.useForm();

  const [RAMFormBlock] = Form.useForm();

  //  const [RAMPartNumbersForm] = Form.useForm();

  // Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('dashboard', dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage('allInventory', dispatch)}
        >
          All Inventory
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Add Stock</Breadcrumb.Item>
    </Breadcrumb>
  );

  //  Tab onClick
  const addSupplierTabClick = (key) => {
    setTabActiveValue(key);
    console.log('Tab clicked key - ', key);
  };

  //  Create Stock Basic Info
  const createStockBasicInfo = (values) => {
    console.log('Create Stock Basic info values - ', values);
    document.getElementById('main').scrollTo(0, 0, 'smooth');
    setTabActiveValue('bareMetalRamTab');
    setTabsToggle({
      tab1: false,
      tab2: false,
      tab3: true,
      tab4: true,
    });
  };

  //  Create Stock Ram Info
  const createStockRamInfo = (values) => {
    console.log('Create Stock Ram values - ', values);
    document.getElementById('main').scrollTo(0, 0, 'smooth');
    setTabActiveValue('bareMetalHddTab');
    setTabsToggle({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: true,
    });
  };

  //  Create Stock HDD Info
  const createStockHddInfo = (values) => {
    console.log('Create Stock HDD values - ', values);
    document.getElementById('main').scrollTo(0, 0, 'smooth');
    setTabActiveValue('bareMetalOthersTab');
    setTabsToggle({
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
    });
  };

  //  Finally submitted
  const finallyAddAll = async () => {
    setErrors(null);
    const bmBasic = await BMBasicInfoForm.validateFields()
      .then((res) => res)
      .catch(() => { setTabActiveValue('baremetalTab'); });
    const bmRam = await BMRamForm.validateFields()
      .then((res) => res)
      .catch(() => { setTabActiveValue('bareMetalRamTab'); });
    const bmHdd = await BMHddForm.validateFields()
      .then((res) => res)
      .catch(() => { setTabActiveValue('bareMetalHddTab'); });
    const bmOthers = await BMOthersForm.validateFields()
      .then((res) => res)
      .catch(() => { setTabActiveValue('bareMetalOthersTab'); });

    console.log('BareMetal Basic Info - ', bmBasic);
    console.log('BareMetal RAM - ', bmRam);
    console.log('BareMetal HDD - ', bmHdd);
    console.log('BareMetal Others - ', bmOthers);

    const addBaremetal = {
      location: locationValue || '',
      currency: baseCurrency || '',
      processor_master_id: bmBasic ? bmBasic.processor.split('ID-')[1] : '',
      name: bmBasic ? bmBasic.processor.split('ID-')[0] : '',
      processor_qty: bmBasic ? Number(bmBasic.processor_qty) : '',
      sku_id: bmBasic ? bmBasic.sku_id : '',
      brand: bmBasic ? bmBasic.brand : '',
      model_no: bmBasic ? bmBasic.model_no : '',
      par_level: bmBasic ? bmBasic.par_level : '',
      ram: {
        ram_type: bmRam ? bmRam.ram_type : '',
        max_slots: bmRam ? Number(bmRam.max_slots) : '',
        ecc: bmRam ? ramEccValue : false,
        max_memory_per_slot: bmRam ? Number(bmRam.max_memory_per_slot) : '',
        max_speed: bmRam ? Number(bmRam.max_speed.split('MHz')[0]) : 0,
      },
      hdd: {
        form_factor: bmHdd ? bmHdd.form_factor : '',
        max_slots: bmHdd ? Number(bmHdd.max_hdd) : '',
        //  dualpower: bmHdd ? bmHdd.dualpower : '',
        protocol: bmHdd ? bmHdd.hdd_protocol : '',
        disk_type: bmHdd ? bmHdd.disk_type : '',
      },
      others: {
        lan_brand: bmOthers ? bmOthers.lan_brand : '',
        max_lan_supported: bmOthers ? Number(bmOthers.lan_cards_supported) : '',
        lan_inserted: bmOthers ? Number(bmOthers.lan_card_inserted) : '',
        //  power_supply: bmOthers ? bmOthers.power_supply : '',
        raid: bmOthers ? bmOthers.raid_card : '',
        optical_drive: bmOthers ? bmOthers.optical_drive === true : false,
        //
        dual_power: bmHdd ? bmHdd.dualpower : '',
      },
      parts: bmOthers.part2mac,
      batches: [
        {
          purchase_order: bmBasic ? bmBasic.purchase_order : '',
          purchase_date: purchaseDateValue,
          warranty_exp_date: warrantyDateValue,
          support_exp_date: supportDateValue,
          ownership_type: bmBasic ? bmBasic.ownership_type : '',
          price: bmBasic && bmBasic.price ? bmBasic.price : 0,
          product_type: bmBasic ? bmBasic.product_type : '',
          supplier: bmBasic ? bmBasic.supplier : '',
        },
      ],
      qty: bmOthers ? Number(bmOthers.qty) : '',
    };

    console.log('Add Supplier Body - ', addBaremetal);

    POST('inventory/baremetal', state.token, addBaremetal).then((res) => {
      if (res.success === true) {
        LoadPage('allInventory', dispatch);
        notification.success({
          message: 'Successfully Added!',
          description: 'Supplier successfully added!',
        });
      } else {
        const errorsList = (
          <ul className="mb-0">
            {
              res.errors.detail.map((resErr) => (
                <li>{resErr.msg}</li>
              ))
            }
          </ul>
        );
        setErrors(errorsList);
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Something went wrong! Please try again!',
        });
      }
    });
  };

  //  Create HDD Basic
  const createHDDBasic = (values) => {
    console.log('Create HDD Basic values - ', values);
    document.getElementById('main').scroll(0, 0, 'smooth');
    setTabActiveValue('hddPartNumberTab');
    HDDPartNumbersForm.setFields(partCountValue);
  };

  //  HDD Part Number Success
  const createHDDPartNumber = async (values) => {
    const hddBasic = await HDDFormBlock.validateFields();
    console.log('HDD Basic - ', hddBasic);

    const hddBody = {
      location: locationValue || '',
      currency: baseCurrency || '',
      sku_id: hddBasic.sku_id,
      hdd: hddBasic.hdd_hdd.split('-')[0],
      qty: Number(hddBasic.hdd_qty),
      master_id: hddBasic.hdd_hdd.split('-')[1],
      batches: [
        {
          purchase_order: hddBasic.purchase_order,
          purchase_date: purchaseDateValue,
          warranty_exp_date: warrantyDateValue,
          support_exp_date: supportDateValue,
          price: hddBasic.price,
          supplier: hddBasic.hdd_supplier,
          //  product_type: 'Brand New',
          //  ownership_type: 'Owned',
        },
      ],
      parts: values.parts,
    };

    console.log('HDD POST Body - ', hddBody);
    POST('inventory/hdds', state.token, hddBody).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Successfully added!',
          description: 'HDD Stock Successfully added!',
        });
        LoadPage('allInventory', dispatch);
      } else {
        const errorsList = (
          <ul className="mb-0">
            {
              res.errors.detail.map((resErr) => (
                <li>
                  {resErr.msg}
                </li>
              ))
            }
          </ul>
        );
        setErrors(errorsList);
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Something went wrong! Please try again!',
        });
      }
    });
  };

  //  RAM Basic Success
  const createRAMBasic = (values) => {
    console.log('Create RAM Basic values - ', values);

    const ramBody = {
      location: locationValue || '',
      currency: baseCurrency || '',
      sku_id: values.sku_id,
      ram: values.ram_ram.split('-')[0],
      qty: values.qty,
      master_id: values.ram_ram.split('-')[1],
      batches: [
        {
          purchase_order: values.purchase_order,
          warranty_exp_date: warrantyDateValue,
          support_exp_date: supportDateValue,
          purchase_date: purchaseDateValue,
          price: values.price,
          supplier: values.ram_supplier,
          qty: values.qty,
        },
      ],
      parts_available: values.qty,
      parts_damaged: 0,
      parts_in_use: 0,
      parts_in_repair: 0,
      parts_in_racked: 0,
    };

    console.log('RAM POST Body - ', ramBody);
    POST('inventory/inv_rams', state.token, ramBody).then((res) => {
      if (res.success === true) {
        notification.success({
          message: 'Successfully added!',
          description: 'RAM Stock Successfully added!',
        });
        LoadPage('allInventory', dispatch);
      } else {
        const errorsList = (
          <ul className="mb-0">
            {
              res.errors.detail.map((resErr) => (
                <li>{resErr.msg}</li>
              ))
            }
          </ul>
        );
        setErrors(errorsList);
        notification.error({
          message: 'Something Went Wrong!',
          description: 'Something went wrong! Please try again!',
        });
      }
    });
  };

  //  Create Stock Others Info
  const createStockOthersInfo = (values) => {
    console.log('Create Stock Others values - ', values);
    finallyAddAll();
  };

  //  Category on Change
  const categoryOnChange = (value) => {
    console.log('Category on change - ', value);
    setCategoryValue(value);
    switch (value) {
      case 'baremetal':
        setTabActiveValue('baremetalTab');
        break;

      case 'inv_hdds':
        setTabActiveValue('hddsTab');
        break;

      case 'inv_rams':
        setTabActiveValue('ramsTab');
        break;

      default:
        break;
    }
  };

  //  Processor on Change
  const processorOnChange = (value) => {
    console.log('Processor on change value - ', value);
    //  setProcessorValue(value);
  };

  //  Brand on Change
  const brandOnChange = (value) => {
    console.log('Brand on change - ', value);
    //  setBrandValue(value);
  };

  //  Product Tyupe on change
  const productTypeOnChange = (e) => {
    console.log('Product type on change - ', e);
    //  setProductTypeValue(e.target.value);
  };

  //  Supplier on change
  const supplierOnChange = (value) => {
    console.log('Supplier on change - ', value);

    /* selectedSuplier.map((res) => (
      setCurrencyValue(res.currency);
    )); */
    console.log('Selected supplier - ', value.split('-')[1]);
    console.log('Location all - ', locationsAll);

    if (locationsAll !== null) {
      const baseCurrencySymbol = locationsAll
        .filter((itm) => itm.base_currency === value.split('-')[1])[0];
      console.log('Supplier base currency symbol = ', baseCurrencySymbol);
      setCurrencyValue(baseCurrencySymbol ? baseCurrencySymbol.base_currency_symbol : '');
      setBaseCurrency(baseCurrencySymbol ? baseCurrencySymbol.base_currency : '');
    }
    //  setSupplierValue(date);
  };

  //  Purchase Date on Change
  const purchaseDateOnChange = (value, date) => {
    console.log('Purchase Expiry Date value - ', value.toISOString(true), date);
    setPurchaseDateValue(value.toISOString(true));
  };

  //  Warranty Expiry Date on Change
  const warrayDateOnChange = (value) => {
    console.log('Warranty Expiry Date value - ', value);
    setWarrantyDateValue(value.toISOString(true));
  };

  //  Support Exprity Date on change
  const supportDateOnChange = (value, date) => {
    console.log('Support Expiry Date value - ', value);
    console.log('Support Expiry Date value - ', date);
    setSupportDateValue(value.toISOString(true));
  };

  //  Ownership Type on change
  const ownershipOnChange = (e) => {
    console.log('Ownership on change value - ', e);
    setOwnershipValue(e.target.value);
  };

  //  RAM Type on change
  const ramTypeOnChange = (value) => {
    console.log('RAM type on change - ', value);
    //  setRamTypeValue(value);
  };

  //  RAM Slots on change
  /* const ramSlotsOnChange = (value) => {
    console.log('RAM Slots on change - ', value);
    //  setRamSlotsValue(value);
  }; */

  //  Max HDD on Change
  const maxHddOnChange = (value) => {
    console.log('Max HDD on change - ', value);
    //  setMaxHddValue(value);
  };

  //  Dual Power on change
  const dualpowerOnChange = (value) => {
    console.log('Dual power on change - ', value);
    //  setDualpowerValue(value);
  };

  //  Max Lan Cards Supported on change
  const lardCardSupportedOnChange = (value) => {
    console.log('Lan card supported on change - ', value);
    //  setLanSupportedValue(value);
  };

  //  Lan Card on change
  const lanCardOnChange = (value) => {
    console.log('Lan Card on change value - ', value);
    //  setLanCardValue(value);
  };

  //  Lan Card supported on change
  const lanCardInsertedOnChange = (value) => {
    console.log('Lan card inserted on change value - ', value);
    //  setLanCardInsertedValue(value);
  };

  //  Raid Card on change
  const raidCardOnChange = (value) => {
    console.log('Raid card on change value - ', value);
    //  setRaidCardValue(value);
  };

  //  Optical dirve on change
  const opticalDriveOnChange = (e) => {
    console.log('Optical drive on change value - ', e);
    //  setOpticalDriveValue(e.target.value);
  };

  //  Baremetal PartQuantity on Change
  const bmPartyQtyOnChange = (value) => {
    // setBmPartArray(Number(value));
    // BMOthersForm.setFields((Number(value)));

    const bmPartArrayData = Array(Number(value)).fill(null).map(() => ({
      part_number: '', mac_id: '',
    }));
    setBmPartArray(bmPartArrayData);

    BMOthersForm.setFieldsValue({
      part2mac: bmPartArrayData,
    });
  };

  //  RAM ECC on change
  const ramEccOnChange = (value) => {
    console.log('BM RAM on change - ', value);
    setRamEccValue(value);
  };

  //  Quantity on change
  const quantityOnChange = (value) => {
    console.log('Quantity on change - ', value);
    setQtyValue(value);
    const partArray = Array(Number(value)).fill(null).map(() => ({
      part_number: '', status: 'Available', batch: 'HARD_CODED',
    }));
    setPartCountValue(partArray);
    HDDPartNumbersForm.setFieldsValue({
      parts: partArray,
    });
    /*  RAMPartNumbersForm.setFieldsValue({
      parts: partArray,
    }); */

    console.log('Party array - ', partArray);
  };

  //  HDD Price on change
  const pricingOnChange = (e) => {
    console.log('Price on change - ', e.target.value);
    setPricing(e.target.value);
    quantityOnChange(qtyValue, e.target.value);
  };

  //  RAM On Change
  const ramOnChange = (value) => {
    const selectedRAM = ramData.filter((item) => item._id === value.split('-')[1]).map((res) => (
      {
        ...res,
        master_ram_id: res._id,
      }
    ));
    console.log('RAM Data - ', ramData, selectedRAM);
    //  setRamSelected(selectedRAM);
  };

  //  HDD On Change
  const hddOnChange = (value) => {
    const selectedHDD = hddData.filter((item) => item._id === value.split('-')[1]).map((res) => (
      {
        ...res,
        master_hdd_id: res._id,
      }
    ));
    console.log('HDD Data - ', hddData, selectedHDD);
    //  setHddSelected(selectedHDD);
  };

  //  Ownership Form
  let OwnershipLeaseForm;
  switch (ownershipValue) {
    case 'Owned':
      OwnershipLeaseForm = (
        <Col span="24">

          <Form.Item
            label="Owned Price"
            name="price"
            rules={[
              {
                required: true,
                message: 'Please enter Owned Price',
              },
            ]}
          >
            <Input
              placeholder="Enter price"
              type="number"
                  //  prefix={currencyValue ? currencyValue.split('-')[0] : ''}
              //  prefix={currencyValue || ''}
              addonBefore={currencyValue ? currencyValue.split('-')[0] : ''}
              className="ps-1"
            />
          </Form.Item>

        </Col>
      );
      break;

    case 'Leased':
      OwnershipLeaseForm = (
        <Col span="24">

          <Form.Item
            label="Leased Price"
            name="price"
            rules={[
              {
                required: true,
                message: 'Please enter Leased Price',
              },
            ]}
          >
            <Input
              placeholder="Enter price"
              addonBefore={currencyValue ? currencyValue.split('-')[0] : ''}
            />
          </Form.Item>

        </Col>
      );
      break;

    default:
      break;
  }

  //  Baremetal RAM Form
  const BaremetalMainForm = (
    <>
      <Form
        name="basic"
        form={BMBasicInfoForm}
        layout="vertical"
        onFinish={createStockBasicInfo}
        autoComplete="off"
        initialValues={{
          support_exp_date: supportDateValue,
          warranty_exp_date: warrantyDateValue,
        }}
      >
        <Row gutter="30">
          <Col span="12">
            <Form.Item
              label="Processor"
              name="processor"
              rules={[
                {
                  required: true,
                  message: 'Please select Processor',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Processor"
                optionFilterProp="children"
                onChange={processorOnChange}
                filterOption={(input, option) => option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                {processorList}
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Processor Quantity"
              name="processor_qty"
              rules={[
                {
                  required: true,
                  message: 'Please select Processor Quantity',
                },
              ]}
            >
              <InputNumber placeholder="Enter Processor quantity" className="w-100" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter="30">
          <Col span="12">
            <Form.Item
              label="SKU ID"
              name="sku_id"
              rules={[
                {
                  required: true,
                  message: 'Please enter SKU ID',
                },
              ]}
            >
              <Input placeholder="Enter SKU ID..." />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Brand"
              name="brand"
              rules={[
                {
                  required: true,
                  message: 'Please select Brand',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select a brand"
                optionFilterProp="children"
                onChange={brandOnChange}
              >
                <Option value="Dell">Dell</Option>
                <Option value="OEM">OEM</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Model#"
              name="model_no"
              rules={[
                {
                  required: true,
                  message: 'Please enter Model',
                },
              ]}
            >
              <Input placeholder="Enter Model..." />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Product Type"
              name="product_type"
              rules={[
                {
                  required: true,
                  message: 'Please select Product Type',
                },
              ]}
            >
              <Radio.Group onChange={productTypeOnChange}>
                <Radio value="Brand New">Brand New</Radio>
                <Radio value="Refurbished">Refurbished</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Par level"
              rules={[
                {
                  required: true,
                  message: 'Please enter Par Level',
                },
              ]}
              name="par_level"
            >
              <Input placeholder="Enter par level..." />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Supplier"
              name="supplier"
              rules={[
                {
                  required: true,
                  message: 'Please select a Supplier',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select a Supplier"
                optionFilterProp="children"
                onChange={supplierOnChange}
                filterOption={(input, option) => option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                {suppliersList}
              </Select>
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="PO#"
              name="purchase_order"
            >
              <Input placeholder="Enter Purchase order..." />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Purchase Date"
              name="purchase_date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Purchase Date',
                },
              ]}
            >
              <DatePicker
                className="w-100"
                format={dateFormat}
                onChange={purchaseDateOnChange}
              />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Warranty Expiry Date"
              name="warranty_exp_date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Warranty Expiry Date',
                },
              ]}
            >
              <DatePicker
                onChange={warrayDateOnChange}
                className="w-100"
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Support Expiry Date"
              name="support_exp_date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Support Expiry Date',
                },
              ]}
            >
              <DatePicker
                onChange={supportDateOnChange}
                className="w-100"
              />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Ownership Type (per month)"
              name="ownership_type"
              rules={[
                {
                  required: true,
                  message: 'Please select Ownership',
                },
              ]}
            >
              <Radio.Group
                onChange={ownershipOnChange}
              >
                <Radio value="Owned">Owned</Radio>
                <Radio value="Leased">Leased</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span="12">
            {OwnershipLeaseForm}
          </Col>
        </Row>
        <Row gutter="30">
          <Col span="24" className="my-1 py-2 border-top">
            <Button type="default" size="large" className="me-1 px-2" htmlType="reset">
              Reset
            </Button>
            <Button
              type="primary"
              size="large"
              className="me-1 btn-success px-4"
              htmlType="submit"
            >
              Proceed to RAM
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );

  //  BaremetalRAM Form
  const BaremetalRAMForm = (
    <Form
      name="ram"
      form={BMRamForm}
      layout="vertical"
      onFinish={createStockRamInfo}
      autoComplete="off"
      initialValues={{

      }}
    >
      <Row gutter="30">
        <Col span="12">
          <Form.Item
            label="RAM Type"
            name="ram_type"
            rules={[
              {
                required: true,
                message: 'Please select a type',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Select a RAM type"
              optionFilterProp="children"
              onChange={ramTypeOnChange}
            >
              <Option value="DDR3">DDR3</Option>
              <Option value="DDR4">DDR4</Option>
              <Option value="DDR5">DDR5</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="RAM Slots"
            name="max_slots"
            rules={[
              {
                required: true,
                message: 'Please enter number of RAM slots',
              },
            ]}
          >
            <InputNumber placeholder="Enter number of slots" className="w-100" />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Max RAM Size Per Slot"
            name="max_memory_per_slot"
          >
            <Input placeholder="Enter only size number" addonAfter="/GB" />
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Speed"
            name="max_speed"
          >
            <Select
              showSearch
              placeholder="Select RAM Speed"
              optionFilterProp="children"
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="800 MHz">800 MHz</Option>
              <Option value="1333 MHz">1333 MHz</Option>
              <Option value="1600 MHz">1600 MHz</Option>
              <Option value="2400 MHz">2400 MHz</Option>
              <Option value="2666 MHz">2666 MHz</Option>
              <Option value="2933 MHz">2933 MHz</Option>
              <Option value="3000 MHz">3000 MHz</Option>
              <Option value="3200 MHz">3200 MHz</Option>
              <Option value="3600 MHz">3600 MHz</Option>
              <Option value="4000 MHz">4000 MHz</Option>
              <Option value="4400 MHz">4400 MHz</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="RAM ECC"
            name="ecc"
            rules={[
              {
                required: true,
                message: 'Enter RAM ECC',
              },
            ]}
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={ramEccOnChange} checked={ramEccValue} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter="30">
        <Col span="24" className="my-1 py-2 border-top">
          <Button type="default" size="large" className="me-1 px-2" htmlType="reset">
            Reset
          </Button>
          <Button
            type="primary"
            size="large"
            className="me-1 btn-success px-4"
            htmlType="submit"
          >
            Proceed to HDD
          </Button>
        </Col>
      </Row>
    </Form>
  );

  //  BaremetalHDD Form
  const BaremetalHDDForm = (
    <Form
      name="hdd"
      form={BMHddForm}
      layout="vertical"
      onFinish={createStockHddInfo}
      autoComplete="off"
      initialValues={{
        dualpower: false,
      }}
    >
      <Row gutter="30">
        <Col span="12">
          <Form.Item
            label="HDD Protocol"
            name="hdd_protocol"
            rules={[
              {
                required: true,
                message: 'Please select HDD protocol',
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select a HDD protocol"
              optionFilterProp="children"
            >
              <Option value="SATA">SATA</Option>
              <Option value="SAS">SAS</Option>
              <Option value="NVMe">NVMe</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="HDD Form Factor"
            name="form_factor"
            rules={[
              {
                required: true,
                message: 'Please select HDD Form Factor',
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select a HDD Form Factor"
              optionFilterProp="children"
            >
              <Option value="2.5">2.5</Option>
              <Option value="3.5">3.5</Option>
              <Option value="M.2">M.2</Option>
              <Option value="U.2">U.2</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Disk type"
            name="disk_type"
            rules={[
              {
                required: true,
                message: 'Please select Disk Type',
              },
            ]}
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select a Disk type"
              optionFilterProp="children"
            >
              <Option value="SSD">SSD</Option>
              <Option value="HDD">HDD</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Max number of HDD Supported"
            name="max_hdd"
            rules={[
              {
                required: true,
                message: 'Please select Max number of Lan Card supported',
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Select a number of HDD supported"
              optionFilterProp="children"
              onChange={maxHddOnChange}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="11">11</Option>
              <Option value="12">12</Option>
              <Option value="13">13</Option>
              <Option value="14">14</Option>
              <Option value="15">15</Option>
              <Option value="16">16</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item
            label="Dual Power Supply"
            name="dualpower"
          >
            <Switch
              onChange={dualpowerOnChange}
              checkedChildren="YES"
              unCheckedChildren="NO"
            />
          </Form.Item>
        </Col>

      </Row>
      <Row gutter="30">
        <Col span="24" className="my-1 py-2 border-top">
          <Button type="default" size="large" className="me-1 px-2" htmlType="reset">
            Reset
          </Button>
          <Button
            type="primary"
            size="large"
            className="me-1 btn-success px-4"
            htmlType="submit"
          >
            Proceed to Others
          </Button>
        </Col>
      </Row>
    </Form>
  );

  //  BaremetalOthers Initial Values
  const BMOthersFormInitialValues = {
    optical_drive: '',
    part2mac: bmPartArray,
  };

  //  BaremetalOthersForm
  const BaremetalOthersForm = (
    <Form
      name="others"
      form={BMOthersForm}
      layout="vertical"
      onFinish={createStockOthersInfo}
      autoComplete="off"
      initialValues={BMOthersFormInitialValues}
    >
      <Row gutter="30">
        <Col span="12">
          <Form.Item
            label="Max Lan Cards Supported"
            name="lan_cards_supported"
            rules={[
              {
                required: true,
                message: 'Please select Max number of Lan Card supported',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select a Max Lan Card supported"
              optionFilterProp="children"
              onChange={lardCardSupportedOnChange}
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="11">11</Option>
              <Option value="12">12</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item
            label="Select Lan Card"
            name="lan_brand"
            rules={[
              {
                required: true,
                message: 'Please select Lan Card',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select Lan Card supported"
              optionFilterProp="children"
              onChange={lanCardOnChange}
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="Intel">Intel</Option>
              <Option value="Chelsio">Chelsio</Option>
              <Option value="Mellinox">Mellinox</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span="12">
          <Form.Item
            label="Lan Card Inserted"
            name="lan_card_inserted"
            rules={[
              {
                required: true,
                message: 'Please select Lan Card Inserted',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select Lan Card inserted"
              optionFilterProp="children"
              onChange={lanCardInsertedOnChange}
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
              <Option value="11">11</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item
            label="RAID Card"
            name="raid_card"
            rules={[
              {
                required: true,
                message: 'Please RAID card',
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select RAID Card"
              optionFilterProp="children"
              onChange={raidCardOnChange}
              filterOption={(input, option) => option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
            >
              <Option value="H200">H200</Option>
              <Option value="H330">H330</Option>
              <Option value="H700">H700</Option>
              <Option value="H710">H710</Option>
              <Option value="H730">H730</Option>
              <Option value="H730P">H730P</Option>
              <Option value="H800">H800</Option>
              <Option value="Software RAID">
                Software RAID
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item
            label="Optical Drive"
            name="optical_drive"
          >
            <Switch
              onChange={opticalDriveOnChange}
              checkedChildren="YES"
              unCheckedChildren="NO"
            />
          </Form.Item>
        </Col>

        <Col span="12">
          <Form.Item
            label="Quantity"
            name="qty"
            rules={[
              {
                required: true,
                message: 'Please enter Part',
              },
            ]}
          >
            <InputNumber onChange={bmPartyQtyOnChange} placeholder="Enter quantity number" className="w-100" />
          </Form.Item>
        </Col>
        <Col span="24">

          <table
            cellPadding="10"
            className="border border-striped table-bordered w-100"
          >
            <thead>
              <tr>
                <td width="50" />
                <td>
                  <strong>Part Numbers</strong>
                </td>
                <td>
                  <strong>Mac Numbers</strong>
                </td>
              </tr>
            </thead>
            <tbody>

              <Form.List
                name="part2mac"
              >
                {(fields) => (
                  <>
                    {fields.map(({
                      key, name, fieldKey,
                    }, ind) => (
                      <tr key={key}>
                        <td>{ind + 1}</td>
                        <td>
                          <Form.Item
                            label=""
                            name={[name, 'part_number']}
                            fieldKey={[fieldKey, 'part_number']}
                            className="m-0"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Part Number',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Part Number..." />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            label=""
                            name={[name, 'mac_id']}
                            fieldKey={[fieldKey, 'mac_id']}
                            className="m-0"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Mac Number',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Mac Number..." />
                          </Form.Item>
                        </td>
                        <td>
                          <Form.Item
                            label=""
                            name={[name, 'status']}
                            fieldKey={[fieldKey, 'status']}
                            initialValue="Available"
                            style={{ width: 0, height: 0, overflow: 'hidden' }}
                            className="m-0"
                          >
                            <Input type="hidden" value="available" />
                          </Form.Item>
                          <Form.Item
                            label=""
                            name={[name, 'batch']}
                            fieldKey={[fieldKey, 'batch']}
                            initialValue="HARD_CODED"
                            style={{ width: 0, height: 0, overflow: 'hidden' }}
                          >
                            <Input type="hidden" value="HARD_CODED" />
                          </Form.Item>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </Form.List>
            </tbody>
          </table>

        </Col>
      </Row>
      <Row gutter="30">
        <Col span="24" className="my-1 py-2 border-top">
          <Button type="default" size="large" className="me-1 px-2" htmlType="reset">
            Reset
          </Button>
          <Button
            type="primary"
            size="large"
            className="me-1 btn-success px-4"
            htmlType="submit"
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );

  //  Baremetal Form
  const BaremetalForm = (
    <Col span="24" className="mb-5 pb-5">
      <Tabs defaultActiveKey="basicInfoTab" className="addStockTabsBlock" onTabClick={addSupplierTabClick} activeKey={tabActiveValue}>
        <TabPane tab="Basic Info" key="baremetalTab">
          {BaremetalMainForm}
        </TabPane>
        <TabPane tab="RAM" key="bareMetalRamTab">
          {BaremetalRAMForm}
        </TabPane>
        <TabPane tab="HDD Disk" key="bareMetalHddTab">
          {BaremetalHDDForm}
        </TabPane>
        <TabPane tab="Others" key="bareMetalOthersTab">
          {BaremetalOthersForm}
        </TabPane>
      </Tabs>
    </Col>
  );

  //  HDD Form
  const HDDForm = (
    <Col span="24" className="mb-5 pb-5">
      <Tabs defaultActiveKey="hddsTab" activeKey={tabActiveValue} onTabClick={addSupplierTabClick} className="addStockTabsBlock">
        <TabPane tab="HDD" key="hddsTab">
          <Form
            name="basic"
            form={HDDFormBlock}
            layout="vertical"
            onFinish={createHDDBasic}
            autoComplete="off"
          >
            <Row gutter="30">
              <Col span="12">
                <Form.Item
                  label="Select HDD"
                  name="hdd_hdd"
                  rules={[
                    {
                      required: true,
                      message: 'Please select HDD',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select HDD"
                    optionFilterProp="children"
                    onChange={hddOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    {hddList}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Quantity"
                  name="hdd_qty"
                  rules={[
                    {
                      required: true,
                      message: 'Please select HDD Quantity',
                    },
                  ]}
                  initialValue={1}
                >
                  <InputNumber onChange={(value) => quantityOnChange(value, pricing)} placeholder="Enter number quntity" maxLength={3} className="w-100" />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="SKU ID"
                  name="sku_id"
                  rules={[
                    {
                      required: true,
                      message: 'Please select SKU ID',
                    },
                  ]}
                >
                  <Input placeholder="Enter SKU ID..." />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Supplier"
                  name="hdd_supplier"
                  rules={[
                    {
                      required: true,
                      message: 'Please select HDD Supplier',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a Supplier"
                    optionFilterProp="children"
                    onChange={supplierOnChange}
                    filterOption={(input, option) => option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0}
                  >
                    {suppliersList}
                  </Select>
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Purchase Date"
                  name="purchase_date"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Purchase Date',
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format={dateFormat}
                    onChange={purchaseDateOnChange}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="PO#"
                  name="purchase_order"
                >
                  <Input placeholder="Enter Purchae order" className="w-100" />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Price per unit"
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter price',
                    },
                  ]}
                  initialValue={0}
                >
                  <Input
                    addonBefore={currencyValue ? currencyValue.split('-')[0] : ''}
                    placeholder="Enter Purchae order"
                    className="w-100"
                    onBlur={pricingOnChange}
                  />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Warranty Expiry Date"
                  name="warranty_exp_date"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Warranty Expiry Date',
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    className="w-100"
                    onChange={warrayDateOnChange}
                  />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Support Expiry Date"
                  name="support_exp_date"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Support Expiry Date',
                    },
                  ]}
                >
                  <DatePicker
                    format={dateFormat}
                    className="w-100"
                    onChange={supportDateOnChange}
                  />
                </Form.Item>
              </Col>
              <Col span="24" className="border-top mb-2 py-2">
                <Button type="default" size="large" className="me-1" htmlType="reset">
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="btn-success"
                >
                  Proceed to Part Numbers
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>

        <TabPane
          tab={(
            <Badge count={Number(qtyValue)}>
              Part Numbers &nbsp;&nbsp;
            </Badge>
            )}
          key="hddPartNumberTab"

        >
          <Form
            name="basic"
            form={HDDPartNumbersForm}
            layout="vertical"
            onFinish={createHDDPartNumber}
            autoComplete="off"
          >
            <Row gutter="30">
              <Form.List
                name="parts"
              >
                {(fields) => (
                  <>
                    {fields.map(({
                      key, name, fieldKey,
                    }, ind) => (
                      <Col span="12" key={key}>
                        <Form.Item
                          label={`Part number ${ind + 1}`}
                          name={[name, 'part_number']}
                          fieldKey={[fieldKey, 'part_number']}
                        >
                          <Input placeholder="Enter Part Number..." />
                        </Form.Item>
                        <Form.Item
                          label=""
                          name={[name, 'status']}
                          fieldKey={[fieldKey, 'status']}
                          className="d-none"
                        >
                          <Input placeholder="Enter Status Number..." defaultValue="Available" disabled />
                        </Form.Item>
                      </Col>
                    ))}
                  </>
                )}
              </Form.List>
              <Col span="24" className="border-top mb-2 py-2">
                <Button type="default" size="large" className="me-1" htmlType="reset">
                  Reset
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="btn-success"
                >
                  Add HDD Stock
                </Button>
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </Col>
  );

  //  HDD Form
  const RAMForm = (
    <Col span="24" className="mb-5 pb-5">
      <Form
        name="basic"
        form={RAMFormBlock}
        layout="vertical"
        onFinish={createRAMBasic}
        autoComplete="off"
      >
        <Row gutter="30">
          <Col span="12">
            <Form.Item
              label="Select RAM"
              name="ram_ram"
              rules={[
                {
                  required: true,
                  message: 'Please select RAM',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select RAM"
                optionFilterProp="children"
                onChange={ramOnChange}
                filterOption={(input, option) => option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                {ramList}
              </Select>
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Quantity"
              name="qty"
              rules={[
                {
                  required: true,
                  message: 'Please select HDD Quantity',
                },
              ]}
              initialValue={1}
            >
              <InputNumber placeholder="Enter number quntity" maxLength={3} className="w-100" />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="SKU ID"
              name="sku_id"
              rules={[
                {
                  required: true,
                  message: 'Please select SKU ID',
                },
              ]}
            >
              <Input placeholder="Enter SKU ID..." />
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="Supplier"
              name="ram_supplier"
              rules={[
                {
                  required: true,
                  message: 'Please select RAM Supplier',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Select a Supplier"
                optionFilterProp="children"
                onChange={supplierOnChange}
                filterOption={(input, option) => option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                {suppliersList}
              </Select>
            </Form.Item>
          </Col>

          <Col span="12">
            <Form.Item
              label="PO#"
              name="purchase_order"
            >
              <Input placeholder="Enter Purchae order" className="w-100" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Price per unit"
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Please enter price',
                },
              ]}
              initialValue={0}
            >
              <Input
                addonBefore={currencyValue ? currencyValue.split('-')[0] : ''}
                placeholder="Enter Purchae order"
                className="w-100"
                onBlur={pricingOnChange}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Purchase Date"
              name="purchase_date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Purchase Date',
                },
              ]}
            >
              <DatePicker
                className="w-100"
                format={dateFormat}
                onChange={purchaseDateOnChange}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Warranty Expiry Date"
              name="warranty_exp_date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Warranty Expiry Date',
                },
              ]}
            >
              <DatePicker
                format={dateFormat}
                className="w-100"
                onChange={warrayDateOnChange}
              />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Support Expiry Date"
              name="support_exp_date"
              rules={[
                {
                  required: true,
                  message: 'Please enter Support Expiry Date',
                },
              ]}
            >
              <DatePicker
                format={dateFormat}
                className="w-100"
                onChange={supportDateOnChange}
              />
            </Form.Item>
          </Col>
          {/* <Col span="12">
            <Form.Item
              label="Parts Available Qty"
              name="parts_available"
              initialValue={0}
            >
              <InputNumber placeholder="Enter Part available quantity" className="w-100" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Parts In Racked Qty"
              name="parts_in_racked"
              initialValue={0}
            >
              <InputNumber placeholder="Enter Part In Racked quantity" className="w-100" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Parts In Use Qty"
              name="parts_in_use"
              initialValue={0}
            >
              <InputNumber placeholder="Enter Part In Use quantity" className="w-100" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Parts In Repair Qty"
              name="parts_in_repair"
              initialValue={0}
            >
              <InputNumber placeholder="Enter Part In Repair quantity" className="w-100" />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              label="Parts In Damaged Qty"
              name="parts_damaged"
              initialValue={0}
            >
              <InputNumber placeholder="Enter Part Damaged quantity" className="w-100" />
            </Form.Item>
          </Col> */}
          <Col span="24" className="border-top mb-2 py-2">
            <Button type="default" size="large" className="me-1" htmlType="reset">
              Reset
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-success"
            >
              Add Stock
            </Button>
          </Col>
        </Row>
      </Form>
    </Col>
  );

  //  Category Form
  let CategoryTabsForm;
  switch (categoryValue) {
    case 'baremetal':
      CategoryTabsForm = (
        <>
          {BaremetalForm}
        </>
      );
      break;

    case 'inv_hdds':
      CategoryTabsForm = (
        <>
          { HDDForm }
        </>
      );
      break;

    case 'inv_rams':
      CategoryTabsForm = (
        <>
          { RAMForm }
        </>
      );
      break;

    default:
      break;
  }

  const locationOnChange = (value) => {
    console.log('Location on change value - ', value);
    setLocationtValue(value.split('-')[0]);
  };

  /* const currencyOnChange = (value) => {
    console.log('Currency on change value - ', value);
    setCurrencyValue(value);
  }; */

  //  Get Currencies
  /*  const getCurrency = async () => {
    GET('masterdata/currencies', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Currency res - ', res);
      if (res.success === true) {
        const currencyListArray = (await res.data
            .map((currency) => (
              <Option key={currency._id} value={`${currency.symbol}-${currency.name}`}>
              {`${currency.symbol}-${currency.name}`}
              </Option>
              )
              ));
        console.log('Currency list after adding - ', currencyListArray);
        setCurrencyList(currencyListArray);
      } else if (res.success === false) {
        message.error('Sorry we did not got currency list from master data!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  }; */

  //  Get Locations
  const getLocations = async () => {
    GET('masterdata/locations', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Locations res - ', res);
      if (res.success === true) {
        setLocationsAll(res.data.locations);
        const locationListArray = (await res.data.locations.map((location) => (<Option key={location._id} value={`${location.name}-${location._id}`}>{`${location.name}, ${location.code}`}</Option>)));
        console.log('Location list after adding - ', locationListArray);
        setLocationList(locationListArray);
        /* const currencyListArray = (await res.data.locations.map((currency) => (
          <Option
            key={currency._id}
            value={`${currency.base_currency_symbol}-${currency.base_currency}`}
          >
            {`${currency.base_currency_symbol}-${currency.base_currency}`}
          </Option>
        ))); */
        // setCurrencyList(currencyListArray);
      } else if (res.success === false) {
        message.error('Sorry we did not got Locations list from master data!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get Suppliers
  const getSuppliers = async () => {
    GET('suppliers', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Suppliers res - ', res);
      if (res.success === true) {
        //  setSupplierData(res.data);
        const supplierListArray = (await res.data.map((supplier) => (<Option key={supplier._id} value={`${supplier.name}-${supplier.currency}`}>{`${supplier.name}`}</Option>)));
        console.log('Suppliers list after adding - ', supplierListArray);
        setSuppliersList(supplierListArray);
      } else if (res.success === false) {
        message.error('Sorry we did not got Supplier list from master data!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get Processors
  const getProcessors = () => {
    GET('masterdata/processors', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get Processors res - ', res);
      if (res.success === true) {
        const processorListArray = (await res.data.processors.map((processor) => (<Option key={processor._id} value={`${processor.make}/${processor.model}/${processor.base_frequency}ID-${processor._id}`}>{`${processor.make}/${processor.model}/${processor.base_frequency}`}</Option>)));
        console.log('Processors list after adding - ', processorListArray);
        setProcessorList(processorListArray);
      } else if (res.success === false) {
        message.error('Sorry we did not got Processors list from master data!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get HDD List
  const getHDDList = () => {
    GET('masterdata/disks', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get HDD Disks res - ', res);
      if (res.success === true) {
        setHddData(res.data.disks);
        const hddListArray = (await res.data.disks.map((disk) => (<Option key={disk._id} value={`${disk.make}/${disk.model}/${disk.disk_type}/${disk.protocol}/${disk.capacity}/${disk.form_factor}-${disk._id}`}>{`${disk.make}/${disk.model}/${disk.disk_type}/${disk.protocol}/${(Math.round(disk.capacity) / 1024).toFixed(1)} TB/${disk.form_factor}`}</Option>)));
        console.log('HDD Disks list after adding - ', hddListArray);
        setHddList(hddListArray);
      } else if (res.success === false) {
        message.error('Sorry we did not got HDD list from master data!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  //  Get RAM List
  const getRAMList = () => {
    GET('masterdata/rams', state.token).then(async (res) => {
      //  setLoading(false);
      console.log('Get RAM res - ', res);
      if (res.success === true) {
        setRamData(res.data.rams);
        const ramsListArray = (await res.data.rams.map((ram) => (<Option key={ram._id} value={`${ram.technology}/${ram.size}/${ram.speed}/${ram.ram_type}-${ram._id}`}>{`${ram.technology}/${ram.size} GB/${ram.speed}/${ram.ram_type}`}</Option>)));
        console.log('RAMs list after adding - ', ramsListArray);
        setRamList(ramsListArray);
      } else if (res.success === false) {
        message.error('Sorry we did not got RAM list from master data!!!');
      }
    }).catch((err) => {
      //  setLoading(false);
      console.log('Get err - ', err);
    });
  };

  useEffect(() => {
    getSuppliers();
    getProcessors();
    getHDDList();
    getRAMList();
    //  getCurrency();
    getLocations();

    quantityOnChange(1);

    BMRamForm.setFieldsValue({
      ecc: ramEccValue,
    });

    //  Breadcrumb
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  useEffect(() => {
    if (state.editPageName === 'addStock') {
      console.log('Edit Form data - ', state.editFormData);
      locationOnChange(state.editFormData.location);
      categoryOnChange(state.editFormData.category);
      setLocationtValue(state.editFormData.location);
      BMBasicInfoForm.setFieldsValue({
        ...state.editFormData,
        processor: state.editFormData.name,
        product_type: state.editFormData.batches.product_type,
        purchase_order: state.editFormData.batches.purchase_order,
        support_exp_date: moment(state.editFormData.batches.support_exp_date),
        warranty_exp_date: moment(state.editFormData.warranty_exp_date),
        ownership_type: state.editFormData.batches.ownership_type,
        price: state.editFormData.batches.price,
      });
      ownershipOnChange({
        target: {
          value: state.editFormData.batches.ownership_type,
        },
      });
      //  setOwnershipValue(state.editFormData.batches.ownership_type);
      if (locationsAll !== null) {
        supplierOnChange(state.editFormData.supplier);
      }
      BMHddForm.setFieldsValue({
        ...state.editFormData.hdd,
        hdd_protocol: state.editFormData.hdd.protocol,
        max_hdd: state.editFormData.hdd.max_slots,
      });
      BMRamForm.setFieldsValue(state.editFormData.ram);
      BMOthersForm.setFieldsValue({
        ...state.editFormData.others,
        lan_cards_supported: state.editFormData.others.max_lan_supported.toString(),
        lan_card_inserted: state.editFormData.others.lan_inserted.toString(),
        raid_card: state.editFormData.others.raid,
        dualpower: state.editFormData.others.dual_power,
        qty: state.editFormData.parts.length,
        part2mac: state.editFormData.parts,
      });
      quantityOnChange(state.editFormData.parts.length);
    }

    return () => {
      dispatch({
        type: EDIT_FORM_DATA,
        payload: {
          editPageName: null,
          editFormData: null,
        },
      });
    };
  }, [locationsAll]);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Add Stock Item
            </Col>
            <Col span="10" flex="row" align="end" />
          </Row>
        </Col>
      </Row>

      <Row gutter={[30, 30]} className="m-2">
        {
         errors ? (
           <Alert
             description={errors}
             type="error"
             className="mb-2"
           />
         ) : ''
       }
        <Col span="24">
          <Row gutter="30">
            <Col span="12">
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: 'Please select Location',
                  },
                ]}
                initialValue={locationValue}
                className="align-items-center"
              >
                <Select
                  placeholder="Select Location"
                  optionFilterProp="children"
                  onChange={locationOnChange}
                  size="large"
                >
                  {locationList}
                </Select>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  {
                    required: true,
                    message: 'Please select Category',
                  },
                ]}
                className="align-items-center"
              >
                <Select
                  showSearch
                  placeholder="Select a category"
                  optionFilterProp="children"
                  onChange={categoryOnChange}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                  size="large"
                >
                  <Option value="baremetal">Bare Metal</Option>
                  <Option value="inv_hdds">HDD</Option>
                  <Option value="inv_rams">RAM</Option>
                </Select>
              </Form.Item>
            </Col>
            {CategoryTabsForm}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddStock;
